import "./wdyr.ts";

import React from "react";
import { render } from "react-dom";
import "focus-visible/dist/focus-visible";
import { ChakraProvider } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import {BrowserRouter as Router} from "react-router-dom";
import { Provider } from "react-redux";

import App from "./App";
import { store } from "./app/store";

import * as serviceWorker from "./serviceWorker";
import { theme, GlobalStyles } from "./uiConfig";
import './translations/i18n.ts';
import "./index.css";
import { checkUserAuthorization } from "./app/session";


checkUserAuthorization()
    .then(() => render(
      <React.StrictMode>
        <Provider store={store}>
          <ChakraProvider theme={theme}>
            <Global styles={GlobalStyles} />
                <Router>
                    <App />
                </Router>
          </ChakraProvider>
        </Provider>
      </React.StrictMode>,
      document.getElementById("root")
    )
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
