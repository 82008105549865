import { ChakraTheme, extendTheme } from "@chakra-ui/react";
import { css } from "@emotion/react";


const colors = {
  brand: {
    primary: "#383A3A",
    secondary: "#8D96A8",
    500: "#267FFF",
    600: "#206DDC",
    700: "#2059AA",
    error: "#F43232",
    darkblue: {
      default: "#405C85",
      light: "rgba(64, 92, 133, 0.6)",
      lightest: "rgba(64, 92, 133, 0.6)",
    },
    background: "#FAFBFF",
    icon: "#267FFF",
    green: "#14BD57",
    red: "#F47D7D",
    yellow: "rgba(255, 184, 0, 0.7)",
    transparent: "transparent",
  },
  blue: {
    500: "#267FFF",
  },
};

export const theme: Partial<ChakraTheme> = extendTheme({
  colors,
  components: {
    Input: {
      defaultProps: {
        focusBorderColor: "brand.500",
        errorBorderColor: "brand.error"
      },
      baseStyle: {
        field: {
          outline: 1,
          //border: "1px solid #267FFF33",
        },
      },
      sizes: {
        lg: {
          field: {
            fontSize: "1rem"
          },
        }
      }
    },
    Switch: {
      baseStyle: {
        track: {
          _focus: {
            boxShadow: "none",
          },
        },
      },
    }
  },
  fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },
});

export const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;