import {lazyWithRetry} from "../utils/common";
import {InnerRoute} from "../app/constants/route";
import {AdminRoles, AllRoles, EmployerRoles, Role} from "../app/constants/user";
import {IProtectedRouteProps} from "./ProtectedRoute";


const DefaultPage = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/registration/DefaultPage")
);

const LoginFlow = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/login/LoginFlow")
);

const LoginMobileNavigation = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/mobile/info/InfoPage")
);

const RegistrationPage = lazyWithRetry(
  () =>
    import(
      /* webpackPrefetch: true */ "../pages/registration/RegistrationFlow"
    )
);

const TermsOfUse = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/documents/TermsOfUse")
);

const PrivacyPolicy = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/documents/PrivacyPolicy")
);

const ContractOfAgency = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/documents/ContractOfAgency")
);

const UserSettings = lazyWithRetry(
  () =>
    import(
      /* webpackPrefetch: true */ "../pages/settings/UserSettings"
    )
);

const NotificationsPage = lazyWithRetry(
  () =>
    import(
      /* webpackPrefetch: true */ "../pages/userAdditionalData/NotificationsPage"
    )
);

const FAQPage = lazyWithRetry(
  () =>
    import(
      /* webpackPrefetch: true */ "../pages/userAdditionalData/FAQPage"
    )
);

const UiKit = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/ui-kit/ui-kit")
);

const SearchEmployeesPage = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/admin/employee/AdminEmployeeSearch")
);

const EmployeeDetailsPage = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/admin/employee/AdminEmployeeBasic")
);

const AdminEmployer = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/admin/employer/AdminEmployerSearch")
);

const AdminUsers = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/admin/managing/users/UsersSearch")
);

const AdminAnalytics = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/admin/analytics/AnalyticsFlow")
);

const AdminAudit = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/admin/managing/audit/AuditFlow")
);

const AdminAuditDetail = lazyWithRetry(
    () => import(/* webpackPrefetch: true */ "../pages/admin/managing/audit/AuditDetail")
)

const AdminPayments = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/admin/payments/AdminPaymentsFlow")
);

const AdminTariffs = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/admin/tariffs/TariffsFlow")
);

const PasswordRecovery = lazyWithRetry(
  () => import(/* webpackPrefetch: true */ "../pages/login/passwordRecovery/RecoveryFlow")
);

const ChangeData = lazyWithRetry(
    () => import("../pages/settings/changeData/ChangeDataFlow")
)

const Dashboard = lazyWithRetry(
    () => import("../pages/employee/Dashboard")
)

const EmployerEmployees = lazyWithRetry(
    () => import("../pages/employer/employee/EmployerEmployeeSearch")
)

const EmployerPayments = lazyWithRetry(
    () => import("../pages/employer/payment/EmployerPaymentsFlow")
)

const EmployerLimits = lazyWithRetry(
    () => import("../pages/employer/limits/EmployerLimitsBasic")
)

const ContactsPage = lazyWithRetry(
  () => import("../pages/contacts/ContactsPage")
)

const ChangeDataMobile = lazyWithRetry(
  () => import("../pages/mobile/changeData/ChangeMobileFlow")
)

const HistoryPage = lazyWithRetry(
  () => import("../pages/history/HistoryFlow")
)

const WithdrawConfirmation = lazyWithRetry(
  () => import("../pages/employee/withdraw/WIthdrawalFlow")
)


const AdminEmployerBasic = lazyWithRetry(
    () => import("../pages/admin/employer/AdminEmployerBasic")
)

const AdminUserBasic = lazyWithRetry(
    () => import("../pages/admin/managing/users/AdminUserBasic")
)

const Cards = lazyWithRetry(
  () => import("../pages/mobile/dashboard/Cards")
)

const History = lazyWithRetry(
  () => import("../pages/mobile/dashboard/Payments")
)

const Profile = lazyWithRetry(
  () => import("../pages/mobile/dashboard/Profile")
)

const ExtendedPaymentsDetail = lazyWithRetry(
    () => import("../pages/employee/payments/ExtendedPaymentsDetail")
)

const TabletMenu = lazyWithRetry(
    () => import("../pages/settings/TabletMenu")
)

const EmployerEmployeeBasic = lazyWithRetry(
    () => import("../pages/employer/employee/EmployerEmployeeBasic")
)

const TariffDetail = lazyWithRetry(
    () => import("../pages/admin/tariffs/TariffDetail")
)

const PaymentDetail = lazyWithRetry(
    () => import("../pages/admin/payments/PaymentDetail")
)

export const publicRoutes: IProtectedRouteProps[] = [
  {
    path: InnerRoute.default,
    component: DefaultPage,
    isAuthorization: true
  },
  {
    path: InnerRoute.login,
    component: LoginFlow,
    isAuthorization: true,
  },
  {
    path: InnerRoute.registration,
    component: RegistrationPage,
    isAuthorization: true
  },
  {
    path: InnerRoute.termsOfUse,
    component: TermsOfUse,
  },
  {
    path: InnerRoute.privacyPolicy,
    component: PrivacyPolicy
  },
  {
    path: InnerRoute.contractOfAgency,
    component: ContractOfAgency
  },
  {
    path: InnerRoute.dashboardMobileCards,
    component: Cards,
    allowedRoles: [Role.employee]
  },
  {
    path: InnerRoute.dashboardMobileHistory,
    component: History,
    allowedRoles: [Role.employee]
  },
  {
    path: InnerRoute.dashboardMobileProfile,
    component: Profile,
    allowedRoles: [Role.employee]
  },
  {
    path: InnerRoute.contacts,
    component: ContactsPage
  },
  {
    path: InnerRoute.dashboardExtendedPayments,
    component: ExtendedPaymentsDetail,
    allowedRoles: [Role.employee]
  },
  {
    path: InnerRoute.settings,
    component: UserSettings,
    allowedRoles: AllRoles
  },
  {
    path: InnerRoute.history,
    component: HistoryPage,
    allowedRoles: AllRoles
  },
  {
    path: InnerRoute.notifications,
    component: NotificationsPage,
    allowedRoles: AllRoles
  },
  {
    path: InnerRoute.faq,
    component: FAQPage,
    allowedRoles: AllRoles
  },
    //TODO should be deleted before prod release
  {
    path: "/ui-kit",
    component: UiKit,
  },
  {
    path: InnerRoute.adminEmployees,
    component: SearchEmployeesPage,
    allowedRoles: [Role.superAdmin, Role.admin]
  },
  {
    path: InnerRoute.settingsMenu,
    component: TabletMenu,
    allowedRoles: [Role.employee]
  },
  {
    path: InnerRoute.adminAnalytics,
    component: AdminAnalytics,
    allowedRoles: AdminRoles
  },
  {
    path: InnerRoute.adminAudit,
    component: AdminAudit,
    allowedRoles: [Role.superAdmin, Role.admin]
  },
  {
    path: InnerRoute.adminPayments,
    component: AdminPayments,
    allowedRoles: AdminRoles
  },
  {
    path: InnerRoute.adminTariffs,
    component: AdminTariffs,
    allowedRoles: AdminRoles
  },
  {
    path: InnerRoute.adminUsers,
    component: AdminUsers,
    allowedRoles: [Role.superAdmin, Role.admin]
  },
  {
    path: InnerRoute.passwordRecovery,
    component: PasswordRecovery,
    isAuthorization: true
  },
  {
    path: InnerRoute.changeData,
    component: ChangeData,
    allowedRoles: AllRoles
  },
  {
    path: InnerRoute.dashboard,
    component: Dashboard,
    allowedRoles: [Role.employee]
  },
  {
    path: InnerRoute.employerEmployees,
    component: EmployerEmployees,
    allowedRoles: [Role.employerSuperAdmin, Role.employerAdmin]
  },
  {
    path: InnerRoute.employerPayments,
    component: EmployerPayments,
    allowedRoles: EmployerRoles
  },
  {
    path: `${InnerRoute.employerLimits}/:detail?`,
    component: EmployerLimits,
    allowedRoles: EmployerRoles
  },
  {
    path: `${InnerRoute.adminEmployee}/:detail?`,
    component: EmployeeDetailsPage,
    allowedRoles: [Role.employerSuperAdmin, Role.employerAdmin, Role.superAdmin, Role.admin]
  },
  {
    path: `${InnerRoute.adminEmployer}/:detail?`,
    component: AdminEmployerBasic,
    allowedRoles: [Role.superAdmin, Role.admin]
  },
  {
    path: `${InnerRoute.adminUser}/:detail?`,
    component: AdminUserBasic,
    allowedRoles: [Role.superAdmin, Role.admin]
  },
  {
    path: InnerRoute.adminEmployers,
    component: AdminEmployer,
    allowedRoles: [Role.superAdmin, Role.admin]
  },
  {
    path: InnerRoute.loginMobileNavigation,
    component: LoginMobileNavigation
  },
  {
    path: InnerRoute.settingsMobile,
    component: ChangeDataMobile,
    allowedRoles: [Role.employee]
  },
  {
    path: InnerRoute.withdrawConfirmation,
    component: WithdrawConfirmation,
    allowedRoles: [Role.employee]
  },
  {
    path: `${InnerRoute.employerEmployees}/:detail?`,
    component: EmployerEmployeeBasic,
    allowedRoles: [Role.employerSuperAdmin, Role.employerAdmin]
  },
  {
    path: InnerRoute.adminTariffsDetail,
    component: TariffDetail,
    allowedRoles: AdminRoles
  },
  {
    path: InnerRoute.paymentDetail,
    component: PaymentDetail,
    allowedRoles: [...AdminRoles, ...EmployerRoles]
  },
  {
    path: InnerRoute.adminAuditDetail,
    component: AdminAuditDetail,
    allowedRoles: [Role.superAdmin, Role.admin]
  }
];
