export const ruTranslation: object = {
  "appName": "ewa",
  "appDescription": "Система выплат зарплат",
  "authorization": {
    "banner": {
      "title": "Удобный и полезный инструмент для вашей жизни",
      "description": "Для регистрации, пожалуйста, введите свой номер телефона и индивидуальный налоговый номер"
    },
    "footer": {
      "support": "Связаться с техподдержкой",
      "faq": "Частые вопросы"
    },
    "login": {
      "title": "Вход",
      "description": "Введите свои учетные данные для доступа в личный кабинет",
      "phone": {
        "label": "Введите ваш номер телефона"
      },
      "password": {
        "label": "Введите пароль"
      },
      "enter": "Войти",
      "isRegister": "Еще не зарегистрированы?",
      "isForgotPassword": "Забыли пароль?"
    },
    "registration": {
      "title": "Регистрация",
      "description": "Введите свои учетные данные для доступа в личный кабинет",
      "phone": {
        "label": "Введите ваш номер телефона"
      },
      "itn": {
        "label": "Введите ИНН",
      },
      "passport": {
        "label": "Введите серию и номер паспорта"
      },
      "itnRefuse": {
        "label": "Лицо отказавшееся от ИНН",
        "description": "Метка проставляется <1>только</1> для лица, которое <1>отказалось от ИНН</1> по религиозным убеждениям"
      },
      "register": "Зарегистрироваться",
      "isRegister": "Вы уже зарегистрированы?",
    },
    "phoneConfirmation": {
      "title": "Подтверждение номера телефона",
      "description": "Для дальнейшей регистрации, введите полученный код, который только что был отправлен на номер телефона",
      "code": {
        "label": "Введите полученный код"
      }
    },
    "deviceConfirmation": {
      "title": "Подтверждение устройства",
      "description": "Для дальнейшего входа, введите полученный код, который только что был отправлен на номер телефона",
      "code": {
        "label": "Введите полученный код"
      }
    },
    "passwordCreation": {
      "title": "Создание пароля",
      "description": "Введите свои учетные данные для доступа в личный кабинет",
      "protectionCode": {
        "label": "Введите пароль"
      },
      "repeatedProtectionCode": {
        "label": "Подтвердите пароль"
      },
      "recommendation": {
        "numbers": "Должны присутсвовать цифры",
        "length": "Не менее 8 символов",
        "lowerLetter": "Должны присутсвовать прописные буквы",
        "camelLetter": "Должны присутсвовать заглавные буквы"
      }
    },
    "passwordChangeCreation": {
      "title": "Создание нового пароля",
      "description": "Создайте новый пароль для личного кабинета",
    },
    "pinChangeCreation": {
      "title": "Создание нового пин-кода",
      "description": "Создайте новый пин-код для личного кабинета",
    },
    "pinCreation": {
      "title": "Создание пин-кода",
      "description": "Введите свои учетные данные для доступа в личный кабинет",
      "protectionCode": {
        "label": "Введите пин-код"
      },
      "repeatedProtectionCode": {
        "label": "Подтвердите пин-код"
      },
      "note": "Пин-код используется для подтверждения денежных операций"
    },
    "dataConfirmation": {
      "title": "Проверка данных",
      "description": "Проверьте свои данные, полученные от работодателя",
      "subtitles": {
        "fullName": "ФИО",
        "company": "Название компании работодателя",
        "position": "Должность сотрудника"
      },
      "note": "Если отображенные данные неверны, свяжитесь с технической поддержкой"
    },
    "rulesConfirmation": {
      "title": "Подпись документов",
      "description": "Для дальнейшей регистрации перейдите по ссылке и подпишите следующие документы",
      "note": "Перейдите по ссылке документа и подпишите каждый документ"
    },
    "rulesConfirmationSuccess": {
      "title": "Успешно подписано!",
      "description": "Все необходимые документы для работы с системой успешно подписаны"
    },
    "end": {
      "title": "Поздравляем!",
      "description": "Ваша заявка на регистрацию в обработке, ожидайте смс с результатом",
      "activatedDescription": "Вы успешно зарегистрированы, выполните вход"
    },
    "passwordRecoveryCreation": {
      "title": "Создание нового пароля!",
      "description": "Для дальнешего восстановления введите новый пароль от личного кабинета.",
      "protectionCode" : {
        "label": "Введите новый пароль"
      },
      "repeatedProtectionCode": {
        "label": "Подтвердите новый пароль"
      }
    },
    "passwordRecovery": {
      "start": {
        "title": "Восстановление пароля",
        "description": "Введите свой номер телефона на который будет отправлено смс с кодом",
        "phone": {
          "label": "Ваш номер телефона"
        }
      },
      "confirmation": {
        "title": "Подтверждение номера телефона",
        "description": "Для дальнейшего восстановления, введите полученный код, который только что был отправлен на номер телефона",
        "code": {
          "label": "Введите полученный код"
        }
      },
      "end": {
        "title": "Поздравляем!",
        "description": "Ваш пароль успешно изменен"
      }
    }
  },
  "documents": {
    "termsOfUse": {
      "name": "Условия пользования",
      "mock1": "Предмет Договора",
      "mock2": "1.1. Банк зобов'язується відкрити Клієнту Поточний рахунок та випустити Картку для Виплат/Картку для Виплат GOLD (далі - Картка) для зарахування грошових коштів у вигляді заробітної плати, соціальних та пенсійних виплат, стипендій, виплат за договорами цивільно-правового характеру, інших виплат та розрахунків, а також здійснювати обслуговування Поточного рахунку, а Клієнт зобов'язується сплачувати Банку винагороду на умовах, визначених цим Договором та Тарифами Банку.",
      "sign": "Я подписываю и соглашаюсь с <2>условиями пользования</2>"
    },
    "privacyPolicy": {
      "name": "Политика конфиденциальности",
      "mock1": "Предмет Договору",
      "mock2": "1.1. Банк зобов'язується відкрити Клієнту Поточний рахунок та випустити Картку для Виплат/Картку для Виплат GOLD (далі - Картка) для зарахування грошових коштів у вигляді заробітної плати, соціальних та пенсійних виплат, стипендій, виплат за договорами цивільно-правового характеру, інших виплат та розрахунків, а також здійснювати обслуговування Поточного рахунку, а Клієнт зобов'язується сплачувати Банку винагороду на умовах, визначених цим Договором та Тарифами Банку.",
      "sign": "Я подписываю и соглашаюсь с <2>политикой конфиденциальности</2>"
    },
    "agreement": {
      "name": "Договор поручения",
      "mock1": "Предмет Договору",
      "mock2": "1.1. Банк зобов'язується відкрити Клієнту Поточний рахунок та випустити Картку для Виплат/Картку для Виплат GOLD (далі - Картка) для зарахування грошових коштів у вигляді заробітної плати, соціальних та пенсійних виплат, стипендій, виплат за договорами цивільно-правового характеру, інших виплат та розрахунків, а також здійснювати обслуговування Поточного рахунку, а Клієнт зобов'язується сплачувати Банку винагороду на умовах, визначених цим Договором та Тарифами Банку.",
      "sign": "Я подписываю и соглашаюсь с <2>договором поручением</2>"
    }
  },
  "button": {
    "back": "Назад",
    "confirm": "Подтвердить",
    "next": "Далее",
    "enter": "Войти",
    "change": "Изменить",
    "toMain": "На главную",
    "yes": "Да",
    "no": "Нет",
    "ok": "Ок",
    "addEmployee": "Добавить сотрудника",
    "addEmployer": "Добавить работодателя",
    "addTariff": "Добавить тариф",
    "addUser": "Добавить пользователя",
    "downloadFromFile": "Загрузить из файла",
    "add": "Добавить",
    "save": "Сохранить",
    "activate": "Активировать"
  },
  "resend": {
        "readyButton": " Отправить",
        "readyText": " смс-код повторно",
        "notReady": "Отправить смс-код повторно через "
  },
  "settings": {
    "header": {
      "logout": {
        "description": "Вы действительно хотите выйти из системы?"
      },
      "adminPanel": {
          "employers": "Работодатели",
          "employees": "Сотрудники",
          "payments": "Выплаты",
          "analytics": "Аналитика",
          "users": "Пользователи",
          "audit": "Аудит",
          "tariffs": "Тарифы",
          "managing": "Администрирование"
      },
      "employerPanel": {
          "employees": "Сотрудники",
          "payments": "Выплаты",
          "limits": "Лимиты"
      }
    },
    "navBar": {
      "settings": "Настройки",
      "history": "История изменений",
      "logout": "Выход"
    },
    "phone": {
      "title": "Смена номера телефона",
      "label": "Текущий номер телефона",
      "labelMobile": "Введите новый номер телефона",
      "confirmation": {
        "description": "Для дальнейшего изменения телефона, введите полученный код, который только что был отправлен на номер телефона"
      },
      "modal": {
        "title": "Вы действительно хотите изменить номер телефона?",
        "description": "Номер телефона будет изменен на "
      },
      "success": {
        "title": "Успешное изменение номера телефона",
        "description": "Поздравляем! Ваш новый номер телефона "
      }
    },
    "email": {
      "title": "Смена электронной почты",
      "titleMobile": "Смена E-mail",
      "label": "E-mail",
      "labelMobile": "Введите новый E-mail",
      "modal": {
        "title": "Вы действительно хотите изменить email?",
        "description": "Email будет изменен на "
      },
      "success": {
        "title": "Успешное изменение Email",
        "description": "Поздравляем! Ваш новый Email "
      }
    },
    "language": {
      "title": "Смена языка интерфейса",
      "label": "Язык",
    },
    "password": {
      "title": "Смена пароля",
      "label": "Смена текущего пароля",
      "confirmation": {
        "description": "Для дальнейшего изменения пароля, введите полученный код, который только что был отправлен на номер телефона"
      },
      "success": {
        "title": "Успешное изменение пароля",
        "description": "Поздравляем! Ваш новый пароль установлен и сохранен"
      }
    },
    "pin": {
      "title": "Смена платежного пин-кода",
      "label": "Смена текущего пин-кода",
      "confirmation": {
        "description": "Для дальнейшего изменения пин-кода, введите полученный код, который только что был отправлен на номер телефона"
      },
      "success": {
        "title": "Успешное изменение пин-кода",
        "description": "Поздравляем! Ваш новый пин-код установлен и сохранен"
      }
    }
  },
  "paginator": {
    "of": "из"
  },
  "defaultSelect": {
    "label": "Все"
  },
  "adminPaymentTable": {
    "headers": {
      "settings": "Настройки",
      "employerType": "Тип юр. лица",
      "employerName": "Работодатель",
      "paymentDate": "Дата выплаты",
      "paymentType": "Тип выплаты",
      "employeeQuantities": "Количество сотрудников",
      "totalAmount": "Общая сумма, грн",
      "totalOwed": "Не выплачено, грн"
    },
    "detail": "Детали выплаты"
  },
  "employeeLimitTable": {
    "headers": {
      "settings": "Настройки",
      "percent": "Частные лимиты",
      "frequencyType": "Частотные лимиты",
      "fullName": "ФИО"
    },
    "deactivate": "Деактивировать"
  },
  "employerTable": {
    "label": {
      "block": "Заблокировать",
      "activate": "Активировать",
      "detail": "Детали работодателя"
    },
    "headers": {
      "settings": "Настройки",
      "employerType": "Тип юр.лица",
      "employerName": "Работодатель",
      "registrationDate": "Дата регистрации",
      "edrpou": "Номер ЕГРПОУ",
      "status": "Статус",
      "plannedBalance": "Запланированный баланс",
      "payedBalance": "Выплачено, грн",
      "previousBalance": "Остаток до выплаты, грн",
      "actualBalance": "Фактический баланс, грн"
    },
    "modal": {
      "confirmation": {
        "title": "Подтверждение изменений",
        "activated": "Вы действительно хотите активировать работодателя?",
        "blocked": "Вы действительно хотите заблокировать работодателя?"
      },
      "success": {
        "title": "Успешно изменено!",
        "activated": "Вы успешно активировали работодателя",
        "blocked": "Вы успешно заблокировали работодателя"
      },
      "error": {
        "title": "Ошибка!"
      }
    }
  },
  "auditTable": {
    "label": {
      "detail": "Детали изменения"
    },
    "headers": {
      "historyId": "Настройки",
      "phone": "Номер телефона",
      "creationDate": "Дата создания",
      "category": "Категория",
      "role": "Роль пользователя",
      "description": "Операция",
      "employerName": "Работодатель",
      "fullName": "ФИО"
    },
  },
  "userTable": {
    "label": {
      "block": "Заблокировать",
      "activate": "Активировать",
      "detail": "Детали пользователя"
    },
    "headers": {
      "settings": "Настройки",
      "lastName": "Фамилия",
      "firstName": "Имя",
      "middleName": "Отчество",
      "role": "Роль пользователя",
      "employer": "Работодатель",
      "phone": "Номер телефона",
      "status": "Статус"
    },
    "modal": {
      "confirmation": {
        "title": "Подтверждение изменений",
        "activated": "Вы действительно хотите активировать пользователя?",
        "blocked": "Вы действительно хотите заблокировать пользователя?"
      },
      "success": {
        "title": "Успешно изменено!",
        "activated": "Вы успешно активировали пользователя",
        "blocked": "Вы успешно заблокировали пользователя"
      },
      "error": {
        "title": "Ошибка!"
      }
    }
  },
  "tariffTable": {
    "label": {
      "edit": "Редактировать",
      "delete": "Удалить"
    },
    "headers": {
      "settings": "Настройки",
      "employerType": "Тип юр. лица",
      "employerName": "Работодатель",
      "tariffType": "Тип тарифа",
      "amount": "Значение",
      "activationDate": "Дата активации",
      "deactivationDate": "Дата деактивации",
      "durationDays": "Продолжительность, дни",
      "status": "Статус",
      "comment": "Комментарий"
    },
    "modal": {
      "delete":{
        "confirmation": {
          "title": "Подтверждение деактивации",
          "description": "Вы действительно хотите деактивировать тариф?",
        },
        "success": {
          "title": "Успешно деактивировано!",
          "description": "Вы успешно деактивировали тариф."
        },
        "error": {
          "title": "Ошибка!"
        }
      },
    }
  },
  "employeeTable": {
    "headers": {
      "activationDate": "Дата активации",
      "fullName": "ФИО",
      "position": "Должность",
      "itn": "ИНН",
      "phone": "Номер телефона",
      "category": "Категория"
    },
    "tooltip": {
      "detail": "Детали сотрудника"
    }
  },
  "employeeBankInfoTable": {
    "header": {
      "main": "Для официальных выплат",
      "accountType": "Тип банковских данных",
      "number": "Номер карты/IBAN",
      "name": "Название карты/IBAN",
    },
    "tooltip": {
      "edit": "Редактировать"
    }
  },
  "employerForm": {
    "mainInfo": "Главная информация про работодателя",
    "type": "Тип юридического лица",
    "employer": "Работодатель",
    "edrpou": "Номер ЕГРПОУ",
    "registrationDate": "Дата регистрации",
    "address": "Адрес работодателя",
    "phoneNumber": "Номер телефона",
    "endDate": "Дата разорвания договора",
    "paymentInfo": "Информация про дни выплаты зарплаты и количество сотрудников",
    "paymentDay": "Число месяца зарплаты",
    "prePaymentDay": "Число месяца аванса",
    "activeEmployees": "Активные сотрудники",
    "blockedEmployees": "Заблокированные сотрудники",
    "allEmployees": "Все сотрудники",
    "paymentsAndBalanceInfo": "Выплаты и баланс работодателя",
    "yetToPay": "Остаток до выплаты, грн",
    "actualBalance": "Фактический баланс, грн",
    "plannedBalance": "Запланированный баланс, грн",
    "totalPaidThisMonth": "Всего выплачено за месяц, грн",
    "totalPaid": "Всего выплачено, грн",
    "bankInfo": "Информация про банковские данные работодателя",
    "bankName": "Название банка",
    "mfo": "МФО",
    "bankAccount": "Банковский счет",
    "modal": {
      "success": {
        "title": "Успех!",
        "bankAccountAdding": "Банковский счет успешно добавлен",
        "bankAccountDeleting": "Банковский счет успешно удален"
      }
    }
  },
  "employeeForm": {
    "employee": "Сотрудник",
    "employer": "Работодатель",
    "personalData": "Персональные данные",
    "balance": "Баланс",
    "payments": "История выплат",
    "history": "История изменений"
  },
  "history": {
    "date": "Дата и время",
    "description": "Операция",
    "author": "Автор",
    "login": "Логин"
  },
  "contacts": {
    "title": "Контакты",
    "description": "Если у вас возникли вопросы, свяжитесь с нами",
    "address": "г. Киев, ул. Шевченко, 145, офис 2"
  },
  "userAdditionalData": {
    "switcher": {
      "notification": "Оповещения",
      "faq": "Частые вопросы"
    }
  },
  "notifications": {
    "readAll": "Отметить все, как прочитанные",
    "title": "Оповещения",
    "checkAll": "Просмотреть все",
    "empty": "Тут будут ваши оповещения"
  },
  "error": {
    "title": "Ошибка",
    "network": {
      "header": "Ошибка во время обработки данных",
      "default": "Упс, что-то произошло, пожалуйста, попробуйте позже."
    },
    "inner": {
      "1": {
        "label": "Неверно заполненный телефон",
        "description": "Поле должно быть заполнено и содержать символ \"+\" и 12 цифр."
      },
      "2": {
        "label": "Неверно заполненный ИНН",
        "description": "Поле должно быть заполнено и содержать 10 цифр."
      },
      "3": {
        "label": "Неверно заполненный паспорт",
        "description": "Поле должно быть заполнено."
      },
      "4": {
        "label": "Неверно заполненный код",
        "description": "Поле должно быть заполнено и содержать 6 цифр."
      },
      "5": {
        "label": "Неверно заполненный пароль",
        "description": "Поля должны быть заполнены одинаково и содержать не менее 8 символов, 1 цифры, 1 строчной и 1 прописной латинской буквы."
      },
      "6": {
        "label": "Неверно заполненное поле",
        "description": "Поле должно быть заполнено."
      },
      "7": {
        "label": "Неверно заполненный пин-код",
        "description": "Поле должно быть заполнено и содержать 4 цифры."
      },
      "8": {
        "label": "Неверно заполненный Email",
        "description": "Поле должно быть заполнено и содержать корректный формат."
      },
      "9": {
        "label": "Неверно выбраный язык",
        "description": "Выбраный язык не найден в списке доступных."
      },
      "10": {
        "label": "Неверно заполненное поле",
        "description": "Значение не отличается от предыдущего."
      }
    },
    "forms": {
      "required": {
        "label": "Это обязательное поле"
      },
      "phone": {
        "label": "Неверно введенный номер"
      },
      "email": {
        "label": "Неверно введенный email"
      },
      "edrpou": {
        "label": "ЕГРПОУ должен состоять из 8 или 10 цифр"
      },
      "itn": {
        "label": "Введите или ИНН, или номер паспорта",
        "value": "ИНН должен состоять из 10 цифр"
      },
      "password": {
        "format": "Пароль должен содержать не менее 8 символов, 1 цифры, 1 строчной и 1 прописной латинской букв",
        "equals": "Поле должно равнятся полю \"Пароль\""
      },
      "number": {
        "positive": "Значенние должно быть числом большим 0"
      },
      "oneOf": {
        "tariffStep": "Поле \"Сумма с\" или \"Сумма по\" должно быть заполнено"
      },
      "date": {
        "label": "Дата должна быть в формате дд.мм.гггг"
      },
      "card": {
        "label": "Номер карты должен состоять из 16 цифр"
      },
      "outOfMonth": {
        "label": "Дата выплаты должна быть существующей датой месяца"
      },
      "notSelected": {
        "label": "Выберите данные из выпадающего списка"
      }
    }
  },
  "footer": {
    "version": "Версия"
  },
  "session": {
    "end": {
      "description": "Ваша сессия закончилась. Пожалуйста, авторизируйтесь еще раз"
    }
  },
  "admin": {
    "employer": {
      "label": "Работодатель",
      "navigation": {
        "detail": "Общие данные",
        "employees": "Сотрудники",
        "tariffs": "Тарифы",
        "history": "История изменений"
      },
      "history": {
        "creationDate": "Дата и время",
        "commentary": "Комментарий",
        "author": "Пользователь",
        "description": "Изменение"
      },
      "add": {
        "title": "Добавление работодателя"
      },
      "balance": {
        "title": "Изменение баланса",
        "label": "Сумма изменения",
        "tooltip": {
          "add": "Пополнение",
          "substract": "Отчисление"
        },
        "success": {
          "title": "Успех!",
          "description": "Баланс успешно изменен"
        },
        "confirmation": {
          "title": "Подтверждение изменения",
          "descriptionStart": "Вы действительно хотите изменить баланс на ",
          "descriptionEnd": " грн?"
        },
        "comment": {
          "label": "Комментарий",
          "placeholder": "Причина изменения баланса"
        }
      }
    },
    "user": {
      "label": "Пользователь",
      "navigation": {
        "detail": "Личные данные",
        "history": "История изменений"
      },
      "add": {
        "title": "Добавление пользователя"
      },
      "detail": {
        "title": "Главная информация про пользователя",
        "modal": {
          "success": {
            "title": "Успех!",
            "change": "Данные пользователя успешно изменены",
            "create": "Пользователь успешно создан."
          },
          "error": {
            "title": "Ошибка!",
            "sameData": "Данные для изменения должны отличаться от предыдущих."
          }
        },
        "form": {
          "lastName": {
            "label": "Фамилия",
            "placeholder": "Иванов"
          },
          "firstName": {
            "label": "Имя",
            "placeholder": "Иван"
          },
          "middleName": {
            "label": "Отчество",
            "placeholder": "Иванович"
          },
          "password": {
            "label": "Пароль"
          },
          "repeatedPassword": {
            "label": "Подтвердите пароль",
          },
          "role": {
            "label": "Роль пользователя",
          },
          "employer": {
            "label": "Работодатель"
          },
          "address": {
            "placeholder": "г. Киев, ул. Жилянская, 62/64, кв. 12"
          }
        }
      }
    },
    "audit": {
      "detail": {
        "title": "Детализированный аудит изменения",
        "previousTableTitle": "Предыдущие изменения",
        "field": {
          "phone": "Номер телефона",
          "creationDate": "Дата изменения",
          "description": "Описание",
          "employerFullName": "Название работодателя",
          "role": "Роль пользователя",
          "fullName": "ФИО пользователя",
          "author": "Автор",
          "comment": "Комментарий",
          "value": "Новое значение"
        },
      }
    },
    "employee": {
      "balance": {
        "title": "Інформация про данные баланса на",
        "monthSalary": "Оклад за месяц, грн",
        "dayRate": "Оклад за день, грн",
        "monthWriteOff": "Выведено за месяц, грн",
        "writeOffPossibility": "Доступно для выведения, грн",
        "totalWriteOff": "Всего выведено, грн",
        "totalEarned": "Заработано, грн"
      },
      "add": {
        "title": "Добавление сотрудника"
      }
    },
    "tariff": {
      "add": {
        "title": "Добавление тарифа"
      },
      "detail": {
        "title": "Информация про тариф",
        "modal": {
          "success": {
            "title": "Успех!",
            "change": "Данные тарифа успешно изменены",
            "create": "Тариф успешно создан"
          },
          "error": {
            "title": "Ошибка!"
          },
          "warning": {
            "title": "Внимание!",
            "message": "Вы не внесли никаких изменений"
          }
        },
        "form": {
          "type": {
            "label": "Тип тарифа"
          },
          "activationDate": {
            "label": "Дата действия, с"
          },
          "deactivationDate": {
            "label": "Дата действия, по"
          },
          "amount": {
            "label": "Сумма, грн",
            "placeholder": "0.0 грн"
          },
          "percent": {
            "label": "Процент",
            "placeholder": "0.0 %"
          },
          "step": {
            "modal": {
              "title": "Шаг выведения"
            },
            "fromAmount": "Сумма выведения с, грн",
            "toAmount": "Сумма выведения по, грн",
            "rate": "Сумма, грн"
          },
          "comment": {
            "label": "Комментарий",
            "placeholder": "Пояснения к тарифу"
          },
          "edit": "Редактировать",
          "delete": "Удалить"
        }
      }
    },
    "payments": {
      "detail": {
        "title": "Информация о выплате",
        "employeesQuantity": {
          "label": "Количество сотрудников"
        },
        "totalAmount": {
          "label": "Общая сумма, грн"
        },
        "employerName": {
          "label": "Работодатель"
        },
        "employeeTable": {
          "headers": {
            "employees": "Сотрудники",
            "amount": "Сумма выплаты, грн",
            "itn": "ИНН",
            "passport": "Паспорт"
          },
          "modal": {
            "title": "Выплата сотруднику",
            "employee": {
              "label": "Сотрудник",
              "placeholder": "ФИО сотрудника..."
            },
            "amount": {
              "label": "Сумма выплаты, грн"
            },
            "itn": {
              "label": "ИНН"
            },
            "passport": {
              "label": "Паспорт"
            }
          }
        },
        "creation": {
          "confirmation": {
            "title": "Подтверждение выплаты",
            "description": "Вы действительно хотите создать выплату?"
          },
          "success": {
            "title": "Успех!",
            "description": "Выплата успешно создана"
          },
          "error": {
            "title": "Ошибка!",
            "description": "Ни одна выплата не создана"
          }
        }
      }
    },
    "employeeDetails": {
      "modal": {
        "error": "Ошибка!"
      }
    }
  },
  "employer": {
    "info": {
      "balance": "Баланс компании",
      "todayPayment": "Сумма, выплаченная за день",
      "employeesQuantity": "Количество активных сотрудников"
    },
    "limits": {
      "navigation": {
        "general": "Глобальный",
        "personal": "Персонализированный"
      },
      "modal": {
        "confirmation": {
          "title": "Деактивация лимита",
          "question": "Вы действительно хотите деактивировать лимит?"
        },
        "success": {
          "title": "Успех!",
          "deactivation": "Лимит успешно деактивирован",
          "global": "Данные глобального лимита успешно изменены",
          "personal": "Данные персонального лимита успешно изменены"
        },
        "fail": {
          "title": "Ошибка!",
          "sameData": "Данные для изменения должны отличаться от предыдущих"
        }
      },
      "general": {
        "title": "Глобальные лимиты",
        "hold": {
          "label": "Глобальный холд",
          "description": "Запрет на выведение средств для всех сотрудников"
        },
        "percent": {
          "label": "% от суммы зарплаты",
          "placeholder": "0%"
        },
        "comment": {
          "label": "Описание",
          "placeholder": "Описание глобального холда"
        }
      },
      "private": {
        "title": "Персональный лимит",
        "frequency": {
          "label": "Частотный лимит"
        },
        "percent": {
          "label": "% от суммы зарплаты"
        }
      }
    },
    "employees": {
      "navigation": {
        "personalData": "Персональные данные",
        "limits": "Лимиты"
      }
    }
  },
  "dashboard": {
    "mobile": {
      "toolbar": {
        "profile": "Профиль",
        "main": "Главная",
        "history": "История",
        "cards": "Карты"
      },
      "main": {
        "title": "Выведение средств"
      },
      "profile": {
        "title": "Профиль"
      },
      "history": {
        "title": "История",
        "detail": "Дополнительно"
      }
    },
    "employeePayment": {
      "header": {
        "creationDate": "Дата и время",
        "operationType": "Тип операции",
        "operationView": "Операция",
        "accountType": "Тип банковских данных",
        "accountNumber": "Номер карти/IBAN",
        "previousBalance": "Сумма до, грн",
        "transactionAmount": "Сума операции, грн",
        "subsequentBalance": "Сума после операции, грн",
        "status": "Статус",
        "operationId": "ID операции",
        "receipt": "Квитанция"
      },
      "empty": "Тут будет история ваших выплат",
      "download": "Загрузить",
      "additionalInfo": "Дополнительно",
      "title": "Последние операции",
      "all": "Все"
    },
    "info": {
      "currentAmount": "Заработанная сумма на текущий день",
      "availableAmount": "Доступно к выводу на сегодня",
      "daySalary": "Ставка за день",
      "workingDays": "Количество рабочих дней"
    },
    "welcome": {
      "greeting": "Приветствуем",
      "position": "Должность",
      "organisation": "Компания"
    },
    "withdrawal": {
      "label": "Сумма вывода, грн",
      "comment": "Комментарий",
      "commission": "Комиссия за вывод составляет",
      "all": "Все",
      "paymentLabel": "Выплата средств",
      "cardLabel": "Номер карты / счета",
      "success": {
        "title": "Запрос отправлен в обработку",
        "description": "Ожидайте проведение операции"
      },
      "confirmation": {
        "title": "Подтверждение выведения средств",
        "description": "Введите свой платежный PIN-код",
        "label": "PIN-код",
        "isForgottenPin": "Забыли PIN-код?"
      }
    },
    "card": {
      "label": "Карты и счета",
      "add": "Добавить",
      "main": "Основная",
      "active": "Активная",
      "blocked": "Заблокированая",
      "modal": {
        "title": "Информация про банковские данные сотрудника",
        "accountType": "Тип банковских данных",
        "number": "Номер",
        "name": "Название",
        "status": "Статус карты / счета",
        "active": "Активный",
        "main": "Основной",
        "numberPlaceholder": "Номер карты / счета",
        "namePlaceholder": "Назва карты / счета",
        "error": {
          "title": "Ошибка!"
        },
        "confirmation": {
          "changeMain": {
            "title": "Вы действительно хотите заменить основную карту для официальных выплат?",
            "description": "Только одна карта/счет могут быть определены для официальных вылат",
            "success": {
              "title": "Успешно изменено!",
              "description": "Успешное изменение карты/счета для официальных выплат"
            }
          },
          "block": {
            "title": "Вы действительно хотите удалить карту/счет ",
            "description": "После удаления Вы не сможете восстановить карту/счет снова",
            "success": {
              "title": "Поздравляем!",
              "description": "Ваша карта/счет успешно удалены"
            }
          },
          "edit": {
            "success": {
              "title": "Успешно изменено!",
              "description": "Вы успешно измененили карту/счет"
            }
          },
          "add": {
            "success": {
              "title": "Успешно добавлено!",
              "description": "Вы успешно добавили карту/счет"
            }
          },
        }
      },
    }
  },
  "currency": {
    "uah": "грн."
  },
  "forms": {
    "save": "Сохранить",
    "activate": "Активировать",
    "lastName": "Фамилия",
    "name": "Имя",
    "middleName": "Отчество",
    "activationDate": "Дата активации",
    "status": "Статус",
    "personalId": "ИНН",
    "phone": "Номер телефона",
    "email": "E-mail",
    "creationDate": "Дата создания",
    "category": "Категория",
    "birthDate": "Дата рождения",
    "address": "Адрес",
    "employer": "Работодатель",
    "edrpou": "ЕГРПОУ",
    "position": "Должность",
    "workSchedule": "График работы, дни",
    "workHours": "Часы работы, с - по",
    "salary": "Оклад, грн",
    "isWorking": "Работает",
    "state": "Состояние",
    "termsOfUse": "Условия пользования",
    "policy": "Политика конфиденциальности",
    "contract": "Договор поручение",
    "passport": "Паспорт",
    "comment": "Комментарий",
    "commentPlaceholder": "Введите пояснение по документам, если не все корректно",
    "givenDocuments": "Поданные документы о сотрудничестве",
    "mainInfo": "Главная информация про сотрудника",
    "workInfo": "Информация про работу сотрудника",
    "bankInfo": "Информация про банковские данные сотрудника",
    "chooseTime": "Выбрать время",
    "from": "с",
    "to": "по",
    "unknown": "Неизвестно",
    "notActivated": "Не сделан первый вход"
  }
}