import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "../store";
import {Nullable} from "../customTypes";


export interface IEmployerInfo {
    actualBalance: number;
    todayPayment: number;
    employeesQuantity: number;
}

interface IEmployerState {
    info: Nullable<IEmployerInfo>
}

const initialState: IEmployerState = {
    info: null
};

export const employersSlice = createSlice({
  name: 'employer',
  initialState,
  reducers: {
      setEmployerInfo: (state, action: PayloadAction<Nullable<IEmployerInfo>>) => {
          state.info = action.payload
      },
      setDefault: (state) => {
        Object.assign(state, initialState)
      }
  }
});

export const { setEmployerInfo, setDefault } = employersSlice.actions;

export const selectEmployerInfo = (state: RootState) => state.employer.info;

export default employersSlice.reducer;