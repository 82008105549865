import {makeRequest} from "./network/handler";
import {NetworkRoute} from "./network/route";
import {QueryType} from "./network/models";
import { store } from "./store";
import { setRole } from "./reducer/user";


export const checkUserAuthorization = async () => {

    const response = await makeRequest(
        NetworkRoute.user.status, QueryType.get
    )

    return response.status && response.data ? await store.dispatch(setRole(response.data.roleId)): null;
};