import React from "react";
import {Route, RouteProps, Redirect} from "react-router";

import {Role} from "../app/constants/user";
import {selectRole} from "../app/reducer/user";
import {useAppSelector} from "../app/hooks";
import {Nullable} from "../app/customTypes";
import { InnerRoute } from "../app/constants/route";


export interface IProtectedRouteProps extends RouteProps {
    role?: Role;
    allowedRoles?: Role[];
    key?: number | string;
    isAuthorization?: boolean;
}


export const DefaultRoleComponent = (): JSX.Element => {
    const role: Nullable<Role> = useAppSelector(selectRole);

    return (
        <>
            {
                role === Role.employee ?
                    <Redirect to = { InnerRoute.dashboard } /> :
                [Role.employerAdmin, Role.employerSuperAdmin].includes(role as Role) ?
                    <Redirect to = { InnerRoute.employerEmployees } /> :
                role === Role.employerAccountant ?
                    <Redirect to = { InnerRoute.employerPayments } /> :
                [Role.superAdmin, Role.admin].includes(role as Role) ?
                    <Redirect to = { InnerRoute.adminEmployees } /> :
                role === Role.accountant ?
                    <Redirect to = { InnerRoute.adminTariffs } /> :
                    <Redirect to = { InnerRoute.login } />
            }
        </>
    )
}

const ProtectedRoute = ({ allowedRoles, component, path, key, isAuthorization }:IProtectedRouteProps): JSX.Element => {
    const role: Nullable<Role> = useAppSelector(selectRole);

    if (isAuthorization && role) {
        return <DefaultRoleComponent />
    } else if (!allowedRoles || (role && allowedRoles.includes(role))) {
        return (
            <Route component={component} path={path} key={key} exact/>
        )
    } else {
        return <DefaultRoleComponent />
    }
}

export default ProtectedRoute;