import React from "react";

import { Button, ButtonProps } from "@chakra-ui/react";

export const PrimaryButton = (props: ButtonProps): JSX.Element => {
  return (
    <Button
      className="font-medium"
      fontWeight="500"
      fontSize={props.fontSize || "13px"}
      _hover={{ background: "brand.600" }}
      _active={{ background: "brand.700", border: 0 }}
      _focus={{ border: 0 }}
      colorScheme="brand"
      color="#FFFCFB"
      borderRadius="5"
      border="0"
      {...props}
    >
      {props.children}
    </Button>
  );
};

export const SecondaryButton = (props: ButtonProps): JSX.Element => {
  return (
    <Button
      backgroundColor="#FFFFFF"
      color="brand.500"
      fontSize={props.fontSize || "13px"}
      fontWeight="500"
      _hover={{ background: "#267FFF0A", color: "brand.500" }}
      _active={{ background: "#267FFF33" }}
      _focus={{ background: "267FFF33" }}
      borderRadius="5"
      border="1px solid #267FFF"
      {...props}
    >
      {props.children}
    </Button>
  );
};
