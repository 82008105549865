import { Spinner } from "@chakra-ui/react";
import React from "react";

export const LoadingOverlay = (): JSX.Element => {
    return (
        <div className="w-full h-full fixed top-0 right-0 bottom-0 left-0 z-50">
            <Spinner className="top-2/4 left-2/4 absolute" color="brand.500" />
        </div>
    )
    
}