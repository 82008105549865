import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "../store";
import {Nullable} from "../customTypes";
import {IBasicModalProps} from "../../pages/common/BasicModal";


export enum ChangeType {
    phone = 1,
    password = 2,
    pin = 3,
    email = 5,
    language = 6
}

export enum ChangeStep {
    creation = 1,
    confirmation = 2,
    success = 3
}

export interface IChangeDetail {
    dataId: Nullable<number>;
    type: Nullable<ChangeType>;
    step: Nullable<ChangeStep>;
    value: Nullable<string>;
}

export interface ISettingsState {
    modalState: Nullable<IBasicModalProps>;
    changeDetail: IChangeDetail;
    currentFaqOption: Nullable<number>;
}

export const defaultChangeDetail: IChangeDetail = {
    dataId: null,
    type: null,
    step: null,
    value: null
}

const initialState: ISettingsState = {
    changeDetail: defaultChangeDetail,
    currentFaqOption: null,
    modalState: null,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
      setChangeDetail: (state, action: PayloadAction<IChangeDetail>) => {
          state.changeDetail = action.payload
      },
      setChangeStep: (state, action: PayloadAction<ChangeStep>) => {
          state.changeDetail.step = action.payload
      },
      setChangeDataValue: (state, action: PayloadAction<string>) => {
          state.changeDetail.value = action.payload
      },
      setChangeDataId: (state, action: PayloadAction<number>) => {
          state.changeDetail.dataId = action.payload
      },
      setCurrentFaqOption: (state, action: PayloadAction<Nullable<number>>) => {
          state.currentFaqOption = action.payload
      },
      setModalState: (state, action: PayloadAction<Nullable<IBasicModalProps>>) => {
          state.modalState = action.payload
      },
      setDefault: (state) => {
        Object.assign(state, initialState)
      }
  }
});


export const { setChangeDetail, setChangeStep, setChangeDataValue, setChangeDataId, setDefault,
    setCurrentFaqOption, setModalState
} = settingsSlice.actions;

export const selectChangeDataDetail = (state: RootState) => state.settings.changeDetail;
export const selectCurrentFaqOption = (state: RootState) => state.settings.currentFaqOption;
export const selectModalState = (state: RootState) => state.settings.modalState;

export default settingsSlice.reducer;