import { configureStore, ThunkAction, Action  } from '@reduxjs/toolkit';
import authorizationReducer from './reducer/authorization';
import userReducer from './reducer/user';
import settingsReducer from './reducer/settings';
import tableReducer from './reducer/table';
import dashboardReducer from './reducer/dashboard';
import employerReducer from './reducer/employer';


export const store = configureStore({
  reducer: {
    authorization: authorizationReducer,
    user: userReducer,
    settings: settingsReducer,
    table: tableReducer,
    dashboard: dashboardReducer,
    employer: employerReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
