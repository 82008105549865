const API_ROUTE: string | undefined = process.env.REACT_APP_API_ROUTE
export const IMAGE_ROUTE: string | undefined = process.env.REACT_APP_IMAGE_ROUTE


export const NetworkRoute = {
    user: {
        login: `${API_ROUTE}/users/login`,
        registration: `${API_ROUTE}/users/registration`,
        code: `${API_ROUTE}/users/code`,
        registrationPhoneConfirmation: `${API_ROUTE}/users/registration/phone`,
        password: `${API_ROUTE}/users/password`,
        pin: `${API_ROUTE}/users/pin`,
        registrationData: `${API_ROUTE}/users/registration/data`,
        rules: `${API_ROUTE}/users/rules`,
        deviceConfirmation: `${API_ROUTE}/users/device`,
        passwordRecovery: `${API_ROUTE}/users/password/recovery`,
        phone: `${API_ROUTE}/users/phone`,
        email: `${API_ROUTE}/users/email`,
        language: `${API_ROUTE}/users/language`,
        history: `${API_ROUTE}/users/history`,
        historyByAdmin: (id: number) => `${API_ROUTE}/users/${id}/history`,
        logout: `${API_ROUTE}/users/logout`,
        status: `${API_ROUTE}/users/status`,
        statuses: `${API_ROUTE}/users/statuses`,
        faq: `${API_ROUTE}/users/faq`,
        basic: `${API_ROUTE}/users/basic`,
        refresh: `${API_ROUTE}/users/token/refresh`,
        users: `${API_ROUTE}/users/`,
        roles: `${API_ROUTE}/users/roles`,
        changeStatus: (id: string | number, status: string) => `${API_ROUTE}/users/${id}/status/${status}`,
        user: (id: number) => `${API_ROUTE}/users/${id}`
    },
    employer: {
        types: `${API_ROUTE}/employers/types`,
        employers: `${API_ROUTE}/employers/`,
        setEmployer: (id: number) => `${API_ROUTE}/employers/${id}`,
        tariffTypes: `${API_ROUTE}/employers/tariffs/types`,
        tariffStatuses: `${API_ROUTE}/employers/tariffs/statuses`,
        tariffs: `${API_ROUTE}/employers/tariffs`,
        tariff: (employerId: number, tariffId: number) => `${API_ROUTE}/employers/${employerId}/tariffs/${tariffId}`,
        info: (id: number) => `${API_ROUTE}/employers/${id}/info`,
        basic: (id: number) => `${API_ROUTE}/employers/${id}/basic`,
        names: `${API_ROUTE}/employers/names`,
        addTariff: (id: number) => `${API_ROUTE}/employers/${id}/tariffs`,
        basicStatistics: `${API_ROUTE}/employers/statistics/main`,
        limits: `${API_ROUTE}/employers/limit`,
        statuses: `${API_ROUTE}/employers/statuses`,
        changeStatus: (id: string, status: string) => `${API_ROUTE}/employers/${id}/statuses/${status}`,
        limitFrequencyTypes: `${API_ROUTE}/employers/withdrawals/frequencies/types`,
        paymentMethods: (id: number) => `${API_ROUTE}/employers/${id}/payment/methods`,
        exactPaymentMethod: (employerId: number, paymentMethodId: number) =>
            `${API_ROUTE}/employers/${employerId}/payment/methods/${paymentMethodId}`,
        enrollments: `${API_ROUTE}/employers/enrollments`,
        enrollment: (id: number) => `${API_ROUTE}/employers/enrollments/${id}`,
        enrollmentsTypes: `${API_ROUTE}/employers/enrollments/types`,
        balance: (id: number) => `${API_ROUTE}/employers/${id}/balance`,
        history: (id: number) => `${API_ROUTE}/employers/${id}/history`
    },
    notification: {
        notifications: `${API_ROUTE}/notifications/`
    },
    employee: {
        employees: `${API_ROUTE}/employees/`,
        basic: (id: string) => `${API_ROUTE}/employees/${id}/basic`,
        position: (id: string) => `${API_ROUTE}/employees/${id}/position`,
        documents: (id: string) => `${API_ROUTE}/employees/${id}/documents`,
        info: (id?: string | number) => `${API_ROUTE}/employees/${id}/info`,
        cards: (id: string) =>  `${API_ROUTE}/employees/${id}/cards`,
        schedule: `${API_ROUTE}/employees/schedule/types`,
        setEmployee: (id: string | number) => `${API_ROUTE}/employees/${id}`,
        dashboard: `${API_ROUTE}/employees/dashboard`,
        accounts: `${API_ROUTE}/employees/accounts`,
        accountTypes: `${API_ROUTE}/employees/accounts/types`,
        balance: (id: number) => `${API_ROUTE}/employees/${id}/balance`,
        personalLimits: (id: number) => `${API_ROUTE}/employees/${id}/limits`,
        withdrawalFrequencies: (id: number) => `${API_ROUTE}/employees/${id}/withdrawals/frequencies`,
        allLimits: `${API_ROUTE}/employees/limits`,
        employeeAccounts: (id: number) => `${API_ROUTE}/employees/${id}/accounts`,
        comission: (amount: number) => `${API_ROUTE}/employees/withdrawals/amount/${amount}`,
        withdraw: `${API_ROUTE}/employees/withdrawals`,
        confirmWithdraw: (id: number) => `${API_ROUTE}/employees/withdrawals/${id}`
    },
    settings: {
        contacts: `${API_ROUTE}/settings/contacts`
    },
    payments: {
        payments: `${API_ROUTE}/payments/`,
        statuses: `${API_ROUTE}/payments/statuses`,
        paymentsType: `${API_ROUTE}/payments/operations/types`,
        paymentsViewType: `${API_ROUTE}/payments/operations/view_types`,
        employerPayments: `${API_ROUTE}/payments/employers`
    },
    reports: {
        employeesPayments: `${API_ROUTE}/reports/employees/payments`
    },
    banks: {
        banks: `${API_ROUTE}/banks`
    },
    audit: {
        audit: `${API_ROUTE}/audit/`,
        auditDetail: (id: number, label: string) => `${API_ROUTE}/audit/${id}?label=${label}`,
        categories: `${API_ROUTE}/audit/categories`
    }
}