import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {AuthorizationStep, RecoveryStep, RulesConfirmationStep} from "../constants/authorization/models";
import {RootState} from "../store";
import {InnerError} from "../constants/error";
import {INetworkError} from "../network/models";
import {Nullable} from "../customTypes";
import {ICustomErrorProps} from "../../components/customError/CustomError";
import {Role} from "../constants/user";


export interface IConfirmedDocument {
  termsOfUse: boolean;
  privacyPolicy: boolean;
  agreement: boolean;
}

export interface IRecovery {
  step: RecoveryStep;
  changeDataId: Nullable<number>;
}

export interface IAuthorizationState {
    phone: string;
    step: AuthorizationStep;
    error: ICustomErrorProps;
    rulesConfirmationStep: RulesConfirmationStep;
    confirmedDocument: IConfirmedDocument;
    recovery: IRecovery;
    temporaryRole: Nullable<Role>;
    isActivated: boolean;
}

const initialState: IAuthorizationState = {
  phone: '',
  step: AuthorizationStep.start,
  rulesConfirmationStep: RulesConfirmationStep.data,
  confirmedDocument: {
    termsOfUse: false,
    privacyPolicy: false,
    agreement: false
  },
  isActivated: false,
  error: {
    inner: null,
    network: null
  },
  recovery: {
    step: RecoveryStep.start,
    changeDataId: null
  },
  temporaryRole: null
};

export const authorizationSlice = createSlice({
  name: 'authorization',
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<AuthorizationStep>) => {
      state.step = action.payload
    },
    setPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload
    },
    setInnerError: (state, action: PayloadAction<Nullable<InnerError>>) => {
      state.error.inner = action.payload
    },
    setNetworkError: (state, action: PayloadAction<Nullable<INetworkError>>) => {
      state.error.network = action.payload
    },
    clearError: (state) => {
      state.error.inner = null
      state.error.network = null
    },
    setConfirmationStep: (state, action: PayloadAction<RulesConfirmationStep>) => {
      state.rulesConfirmationStep = action.payload
    },
    confirmTermOfUse: (state) => {
      state.confirmedDocument.termsOfUse = true
    },
    confirmPrivacyPolicy: (state) => {
      state.confirmedDocument.privacyPolicy = true
    },
    confirmAgreement: (state) => {
      state.confirmedDocument.agreement = true
    },
    setRecoveryStep: (state, action: PayloadAction<RecoveryStep>) => {
      state.recovery.step = action.payload
    },
    setChangeDataId: (state, action: PayloadAction<Nullable<number>>) => {
      state.recovery.changeDataId = action.payload
    },
    setTemporaryRole: (state, action: PayloadAction<Nullable<Role>>) => {
      state.temporaryRole = action.payload
    },
    setIsActivated: (state, action: PayloadAction<boolean>) => {
      state.isActivated = action.payload
    },
    setDefault: (state) => {
        Object.assign(state, initialState)
    }
  }
});

export const {
  setPhone, setStep , setInnerError, setNetworkError, clearError, setConfirmationStep, confirmTermOfUse,
  confirmAgreement, confirmPrivacyPolicy, setRecoveryStep, setChangeDataId, setTemporaryRole, setDefault,
    setIsActivated
} = authorizationSlice.actions;

export const selectAuthorization = (state: RootState) => state.authorization;

export const selectPhone = (state: RootState) => state.authorization.phone;
export const selectTemporaryRole = (state: RootState) => state.authorization.temporaryRole;
export const selectStep = (state: RootState) => state.authorization.step;
export const selectError = (state: RootState) => state.authorization.error;
export const selectRulesConfirmationStep = (state: RootState) => state.authorization.rulesConfirmationStep;
export const selectConfirmedDocument = (state: RootState) => state.authorization.confirmedDocument;
export const selectRecovery = (state: RootState) => state.authorization.recovery;
export const selectIsActivated = (state: RootState) => state.authorization.isActivated;

export default authorizationSlice.reducer;