import { Nullable } from "../customTypes";


// enums
export enum NetworkStatus {
    success= 200,
    empty = 204,
    error = 420
}

export enum RequestStatus {
    failed,
    success
}

export enum QueryType {
    get = "GET",
    post = "POST",
    put = "PUT",
    delete = "DELETE",
    patch = "PATCH"
}

// TODO make ApiResponse accept an actual type of data property for better type checking
export enum ErrorCode {
    expired_token = 614
}

export const CLOSE_SESSION_ERRORS: object = {
    sessionNotFound: 612,
    incorrectCookie: 615,
}

export class ApiResponse implements IResponse {
    status: RequestStatus
    data: Nullable<keyable>
    error: Nullable<INetworkError>

    constructor(status: number, data: Nullable<keyable> = null, error: Nullable<INetworkError> = null) {
        this.status = status
        this.data = data
        this.error = error
    }
}

// interfaces
interface keyable {
    [key: string]: any
}
export interface IResponse {
    status: RequestStatus,
    data: any,
    error: Nullable<INetworkError>
}

export interface INetworkError {
    code: number,
    message: string
}

export interface INetworkErrorExtended {
    network: Nullable<INetworkError>
    field?: Nullable<number>
}
