import React, { Suspense } from "react";
import {Switch, Route, useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

import { publicRoutes } from "./routing/publicRoutes";
import { LoadingOverlay } from "./components/loadingOverlay/LoadingOverlay";
import ProtectedRoute, { DefaultRoleComponent } from "./routing/ProtectedRoute";
import BasicModal from "./pages/common/BasicModal";
import {InnerRoute} from "./app/constants/route";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {
    selectShowEndSessionModal,
    setDefault as setUserDefault
} from "./app/reducer/user";
import { setDefault as setAuthorizationDefault } from "./app/reducer/authorization";
import { setDefault as setSettingsDefault } from "./app/reducer/settings";
import {setDefault as setTableDefault} from "./app/reducer/table";


export default function App() {
    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useAppDispatch();
    const showEndSessionModal: boolean = useAppSelector(selectShowEndSessionModal);

    const endUserSession = (): void => {
      dispatch(setUserDefault());
      dispatch(setAuthorizationDefault());
      dispatch(setSettingsDefault());
      dispatch(setTableDefault());
      return history.push(InnerRoute.login);
    }

  return (
      <div>
        <Suspense fallback={<LoadingOverlay />}>
            <BasicModal isOpen={ showEndSessionModal }
                        onConfirm={ endUserSession }
                        onClose={ endUserSession }
                        description={ t("session.end.description")}
                        primaryText={ t("button.ok")}

            />
            <Switch>
                {publicRoutes.map((route, i) => (
                  <ProtectedRoute key={i} {...route} exact/>
                ))}
                <Route component = { DefaultRoleComponent } />
            </Switch>
        </Suspense>
      </div>
  );
}
