import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {AllowedPageSize, defaultPage, defaultTotal} from "../constants/paginator";
import {RootState} from "../store";


export interface IPaginatorState {
    total: number;
    page: number;
    size: number;
}

export interface ITableState {
    paginator: IPaginatorState;
    tableData: any[];
    filters: object;
}

export interface IModificationData {
    ids: number[];
    key: string;
    value: any;
}

const initialPaginatorState: IPaginatorState = {
    total: defaultTotal,
    page: defaultPage,
    size: Number(AllowedPageSize[0])
};

const initialState: ITableState = {
    paginator: initialPaginatorState,
    tableData: [],
    filters: {}
}

export const tableSlice = createSlice({
  name: 'table',
  initialState: initialState,
  reducers: {
      setTotal: (state, action: PayloadAction<number>) => {
          state.paginator.total = action.payload
      },
      setPage: (state, action: PayloadAction<number>) => {
          state.paginator.page = action.payload
      },
      setPageSize: (state, action: PayloadAction<number>) => {
          state.paginator.size = action.payload
      },
      setTableData: (state, action: PayloadAction<[]>) => {
          state.tableData = action.payload
      },
      setFilters: (state, action: PayloadAction<object>) => {
          state.filters = action.payload
      },
      modifyTableData: (state, action: PayloadAction<IModificationData>) => {
          const { ids, key, value } = action.payload;
          state.tableData = state.tableData.map(data => {
              return ids.includes(data.id) ? {...data, [key]: value} : data
          })
      },
      setDefault: (state) => {
        Object.assign(state, initialState)
      }
  }
});

export const { setTotal, setPage, setPageSize, setDefault, setFilters, setTableData, modifyTableData } = tableSlice.actions;

export const selectTotal = (state: RootState) => state.table.paginator.total;
export const selectPage = (state: RootState) => state.table.paginator.page;
export const selectSize = (state: RootState) => state.table.paginator.size;
export const selectFilters = (state: RootState) => state.table.filters;
export const selectTableData = (state: RootState) => state.table.tableData;


export default tableSlice.reducer;