export const AllowedPageSize = ["5", "10", "25", "50", "100"];
export const defaultPage = 0;
export const defaultTotal = 0;
export const defaultMinQuantity = 4;

export enum PageFlip {
    back = 1,
    forward = 2
}

export const pageSizes = AllowedPageSize.map(val => ({ id: val, value: val}));
