import { getUserLocale } from 'get-user-locale';

import i18n from "../../translations/i18n"
import { Nullable } from "../customTypes";


export enum Language {
    uk = "uk",
    ru = "ru"
}

export interface IServerLanguage {
    id: number;
    name: string;
    code: string;
}

export const changeUserLanguage = async (language: string): Promise<void> => {
    localStorage.setItem('lng', language.toString())
    await i18n.changeLanguage(language.toString())
};

export const getCurrentLanguage = (): string => {
    const storageLng: Nullable<string> = localStorage.getItem("lng");
    const locale: string = getUserLocale();
    if (storageLng){
        return storageLng
        // @ts-ignore
    } else if (locale && Object.values(Language).includes(locale.slice(0,2))) {
        return locale.slice(0,2)
    } else {
        return Language.uk;
    }
};