export const ukTranslation: object = {
  "appName": "ewa",
  "appDescription": "Система виплат зарплат",
  "authorization": {
    "banner": {
      "title": "Зручний та корисний інструмент для вашого життя",
      "description": "Для реєстрації, будь ласка, введіть свій номер телефону та індивідуальний податковий номер"
    },
    "footer": {
      "support": "Зв'язатися з техпідтримкою",
      "faq": "Часті питання"
    },
    "login": {
      "title": "Вхід",
      "description": "Введіть свої облікові дані для доступу в особистий кабінет",
      "phone": {
        "label": "Введіть ваш номер телефону"
      },
      "password": {
        "label": "Введіть пароль"
      },
      "enter": "Увійти",
      "isRegister": "Ви ще не зареєстровані?",
      "isForgotPassword": "Забули пароль?"
    },
    "registration": {
      "title": "Реєстрація",
      "description": "Введіть свої облікові дані для доступу в особистий кабінет",
      "phone": {
        "label": "Введіть ваш номер телефону"
      },
      "itn": {
        "label": "Введіть ІПН",
      },
      "passport": {
        "label": "Введіть серію та номер паспорта"
      },
      "itnRefuse": {
        "label": "Особа, що відмовилась від ІПН",
        "description": "Мітка проставляється <1>тільки</1> для особи, що <1>відмовилася від ІПН</1> за релігійними переконаннями"
      },
      "register": "Зареєструватися",
      "isRegister": "Ви вже зареєстровані?",
    },
    "phoneConfirmation": {
      "title": "Підтвердження номера телефону",
      "description": "Для подальшої реєстрації, введіть отриманий код, який щойно було відправлено на номер телефону",
      "code": {
        "label": "Введіть отриманий код"
      }
    },
    "deviceConfirmation": {
      "title": "Підтвердження пристрою",
      "description": "Для подальшого входу, введіть отриманий код, який щойно було відправлено на номер телефону",
      "code": {
        "label": "Введіть отриманий код"
      }
    },
    "passwordCreation": {
      "title": "Створення паролю",
      "description": "Введіть свої облікові дані для доступу в особистий кабінет",
      "protectionCode": {
        "label": "Введіть пароль"
      },
      "repeatedProtectionCode": {
        "label": "Підтвердіть пароль"
      },
      "recommendation": {
        "numbers": "Мають бути присутні цифри",
        "length": "Не менше 8 символів",
        "lowerLetter": "Повинно містити малі літери",
        "camelLetter": "Повинно містити великі літери"
      }
    },
    "passwordChangeCreation": {
      "title": "Створення нового паролю",
      "description": "Створить новий пароль для особистого кабінету",
    },
    "pinChangeCreation": {
      "title": "Створення нового пін-коду",
      "description": "Створить новий пін-код для особистого кабінету",
    },
    "pinCreation": {
      "title": "Створення пін-коду",
      "description": "Введіть свої облікові дані для доступу в особистий кабінет",
      "protectionCode": {
        "label": "Введіть пін-код"
      },
      "repeatedProtectionCode": {
        "label": "Підтвердіть пін-код"
      },
      "note": "Пін-код використовується для підтвердження грошових операцій"
    },
    "dataConfirmation": {
      "title": "Перевірка даних",
      "description": "Перевірте свої дані, які отримані від роботодавця",
      "subtitles": {
        "fullName": "ПІБ",
        "company": "Назва компанії роботодавця",
        "position": "Посада працівника"
      },
      "note": "Якщо відображені дані некоректні, зв'яжіться з технічною підтримкою"
    },
    "rulesConfirmation": {
      "title": "Підпис документів",
      "description": "Для подальшої реєстрації перейдіть за посланнями та підпишіть наступні документи",
      "note": "Перейдіть за посиланням документу та підпишіть кожен документ"
    },
    "rulesConfirmationSuccess": {
      "title": "Успішно підписано!",
      "description": "Всі необхідні документи для роботи з системою успішно підписано",
    },
    "end": {
      "title": "Вітаємо!",
      "description": "Ваша заявка на реєстрацію в обробці, очікуйте смс з результатом",
      "activatedDescription": "Ви успішно зареєстровані, виконайте вхід"
    },
    "passwordRecoveryCreation": {
      "title": "Створення нового паролю!",
      "description": "Для подальшого відновлення, введіть новий пароль для особистого кабінету",
      "protectionCode" : {
        "label": "Введіть новий пароль"
      },
      "repeatedProtectionCode": {
        "label": "Підтвердіть новий пароль"
      }
    },
    "passwordRecovery": {
      "start": {
        "title": "Відновлення паролю",
        "description": "Введіть свій номер телефону, на який буде відправлено смс з кодом",
        "phone": {
          "label": "Ваш номер телефону"
        }
      },
      "confirmation": {
        "title": "Підтвердження номера телефону",
        "description": "Для подальшого відновлення, введіть отриманий код, який щойно було відправлено на номер телефону",
        "code": {
          "label": "Введіть отриманий код"
        }
      },
      "end": {
        "title": "Вітаємо!",
        "description": "Ваш пароль успішно змінено."
      }
    }
  },
  "documents": {
    "termsOfUse": {
      "name": "Умови користування",
      "mock1": "Предмет Договору",
      "mock2": "1.1. Банк зобов'язується відкрити Клієнту Поточний рахунок та випустити Картку для Виплат/Картку для Виплат GOLD (далі - Картка) для зарахування грошових коштів у вигляді заробітної плати, соціальних та пенсійних виплат, стипендій, виплат за договорами цивільно-правового характеру, інших виплат та розрахунків, а також здійснювати обслуговування Поточного рахунку, а Клієнт зобов'язується сплачувати Банку винагороду на умовах, визначених цим Договором та Тарифами Банку.",
      "sign": "Я підписую та погоджуюсь з <2>умовами користування</2>"
    },
    "privacyPolicy": {
      "name": "Політика конфіденційності",
      "mock1": "Предмет Договору",
      "mock2": "1.1. Банк зобов'язується відкрити Клієнту Поточний рахунок та випустити Картку для Виплат/Картку для Виплат GOLD (далі - Картка) для зарахування грошових коштів у вигляді заробітної плати, соціальних та пенсійних виплат, стипендій, виплат за договорами цивільно-правового характеру, інших виплат та розрахунків, а також здійснювати обслуговування Поточного рахунку, а Клієнт зобов'язується сплачувати Банку винагороду на умовах, визначених цим Договором та Тарифами Банку.",
      "sign": "Я підписую та погоджуюсь з <2>політикою конфіденційності</2>"
    },
    "agreement": {
      "name": "Договір доручення",
      "mock1": "Предмет Договору",
      "mock2": "1.1. Банк зобов'язується відкрити Клієнту Поточний рахунок та випустити Картку для Виплат/Картку для Виплат GOLD (далі - Картка) для зарахування грошових коштів у вигляді заробітної плати, соціальних та пенсійних виплат, стипендій, виплат за договорами цивільно-правового характеру, інших виплат та розрахунків, а також здійснювати обслуговування Поточного рахунку, а Клієнт зобов'язується сплачувати Банку винагороду на умовах, визначених цим Договором та Тарифами Банку.",
      "sign": "Я підписую та погоджуюсь з <2>договором доручення</2>"
    },
  },
  "button": {
    "back": "Назад",
    "confirm": "Підтвердити",
    "next": "Далі",
    "enter": "Увійти",
    "change": "Змінити",
    "toMain": "На головну",
    "yes": "Так",
    "no": "Ні",
    "ok": "Ок",
    "addEmployee": "Додати працівника",
    "addEmployer": "Додати роботодавця",
    "addTariff": "Додати тариф",
    "addUser": "Додати користувача",
    "downloadFromFile": "Завантажити з файлу",
    "add": "Додати",
    "save": "Зберегти",
    "activate": "Активувати"
  },
  "resend": {
        "readyButton": " Надіслати",
        "readyText": " смс-код повторно",
        "notReady": "Надіслати смс-код повторно через "
  },
  "settings": {
    "header": {
      "logout": {
        "description": "Ви дійсно бажаєте вийти з системи?"
      },
      "adminPanel": {
          "employers": "Роботодавці",
          "employees": "Працівники",
          "payments": "Виплати",
          "analytics": "Аналітика",
          "users": "Користувачі",
          "audit": "Аудит дій",
          "tariffs": "Тарифи",
          "managing": "Адміністрування"
      },
      "employerPanel": {
          "employees": "Працівники",
          "payments": "Виплати",
          "limits": "Ліміти"
      }
    },
    "navBar": {
      "settings": "Налаштування",
      "history": "Історія змін",
      "logout": "Вихід"
    },
    "phone": {
      "title": "Зміна номеру телефону",
      "label": "Поточний номер телефону",
      "labelMobile": "Введіть новий номер телефону",
      "confirmation": {
        "description": "Для подальшої зміни телефону, введіть отриманий код, який щойно було відправлено на номер телефону"
      },
      "modal": {
        "title": "Ви дійсно бажаєте змінити номер телефону?",
        "description": "Номер телефону буде змінено на "
      },
      "success": {
        "title": "Успішна зміна номеру телефону",
        "description": "Вітаємо! Ваш новий номер телефону "
      }
    },
    "email": {
      "title": "Зміна електронної пошти",
      "titleMobile": "Зміна E-mail",
      "label": "E-mail",
      "labelMobile": "Введіть новий E-mail",
      "success": {
        "title": "Успішна зміна Email",
        "description": "Вітаємо! Ваш новий Email "
      },
      "modal": {
        "title": "Ви дійсно бажаєте змінити email?",
        "description": "Email буде змінено на "
      },
    },
    "language": {
      "title": "Зміна мови інтерфейсу",
      "label": "Мова"
    },
    "password": {
      "title": "Зміна паролю",
      "label": "Зміна поточного паролю",
      "confirmation": {
        "description": "Для подальшої зміни паролю, введіть отриманий код, який щойно було відправлено на номер телефону"
      },
      "success": {
        "title": "Успішна зміна паролю",
        "description": "Вітаємо! Ваш новий пароль встановлено та збережено"
      }
    },
    "pin": {
      "title": "Зміна платіжного пін-коду",
      "label": "Зміна поточного пін-коду",
      "confirmation": {
        "description": "Для подальшої зміни пін-коду, введіть отриманий код, який щойно було відправлено на номер телефону"
      },
      "success": {
        "title": "Успішна зміна пін-коду",
        "description": "Вітаємо! Ваш новий пін-код встановлено та збережено"
      }
    }
  },
  "paginator": {
    "of": "з"
  },
  "defaultSelect": {
    "label": "Усі"
  },
  "adminPaymentTable": {
    "headers": {
      "settings": "Налаштування",
      "employerType": "Тип юр. особи",
      "employerName": "Роботодавець",
      "paymentDate": "Дата виплати",
      "paymentType": "Тип виплати",
      "employeeQuantities": "Кількість працівників",
      "totalAmount": "Загальна сума, грн",
      "totalOwed": "Не виплачено, грн"
    },
    "detail": "Деталі виплати"
  },
  "employeeLimitTable": {
    "headers": {
      "settings": "Налаштування",
      "percent": "Приватні ліміти",
      "frequencyType": "Частотні ліміти",
      "fullName": "ПІБ"
    },
    "deactivate": "Деактивувати"
  },
  "employerTable": {
    "label": {
      "block": "Заблокувати",
      "activate": "Активувати",
      "detail": "Деталі роботодавця"
    },
    "headers": {
      "settings": "Налаштування",
      "employerType": "Тип юр.особи",
      "employerName": "Роботодавець",
      "registrationDate": "Дата реєстрації",
      "edrpou": "Номер ЄДРПОУ",
      "status": "Статус",
      "plannedBalance": "Запланований баланс",
      "payedBalance": "Виплачено, грн",
      "previousBalance": "Залишок до виплати, грн",
      "actualBalance": "Фактичний баланс, грн"
    },
    "modal": {
      "confirmation": {
        "title": "Підтвердження зміни",
        "activated": "Ви дійсно бажаєте активувати роботодавця?",
        "blocked": "Ви дійсно бажаєте заблокувати роботодавця?"
      },
      "success": {
        "title": "Успішно змінено!",
        "activated": "Ви успішно активували роботодавця",
        "blocked": "Ви успішно заблокували роботодавця"
      },
      "error": {
        "title": "Помилка!"
      }
    }
  },
  "auditTable": {
    "label": {
      "detail": "Деталі зміни"
    },
    "headers": {
      "historyId": "Налаштування",
      "phone": "Номер телефону",
      "creationDate": "Дата створення",
      "category": "Категорія",
      "role": "Роль користувача",
      "description": "Операція",
      "employerName": "Роботодавець",
      "fullName": "ПІБ"
    },
  },
  "userTable": {
    "label": {
      "block": "Заблокувати",
      "activate": "Активувати",
      "detail": "Деталі користувача"
    },
    "headers": {
      "settings": "Налаштування",
      "lastName": "Прізвище",
      "firstName": "Ім’я",
      "middleName": "По батькові",
      "role": "Роль користувача",
      "employer": "Роботодавець",
      "phone": "Номер телефону",
      "status": "Статус"
    },
    "modal": {
      "confirmation": {
        "title": "Підтвердження зміни",
        "activated": "Ви дійсно бажаєте активувати користувача?",
        "blocked": "Ви дійсно бажаєте заблокувати користувача?"
      },
      "success": {
        "title": "Успішно змінено!",
        "activated": "Ви успішно активували користувача",
        "blocked": "Ви успішно заблокували користувача"
      },
      "error": {
        "title": "Помилка!"
      }
    }
  },
  "tariffTable": {
    "label": {
      "edit": "Редагувати",
      "delete": "Видалити"
    },
    "headers": {
      "settings": "Налаштування",
      "employerType": "Тип юр. особи",
      "employerName": "Роботодавець",
      "tariffType": "Тип тарифу",
      "amount": "Значення",
      "activationDate": "Дата активації",
      "deactivationDate": "Дата деактивації",
      "durationDays": "Тривалість, дні",
      "status": "Статус",
      "comment": "Коментар"
    },
    "modal": {
      "delete":{
        "confirmation": {
          "title": "Підтвердження деактивації",
          "description": "Ви дійсно бажаєте деактивувати тариф?",
        },
        "success": {
          "title": "Успішно деактивовано!",
          "description": "Ви успішно деактивували тариф."
        },
        "error": {
          "title": "Помилка!"
        }
      },
    }
  },
  "employeeTable": {
    "headers": {
      "activationDate": "Дата активації",
      "fullName": "ПІБ",
      "position": "Посада",
      "itn": "IПН",
      "phone": "Номер телефону",
      "category": "Категорія"
    },
    "tooltip": {
      "detail": "Детали сотрудника"
    }
  },
  "employeeBankInfoTable": {
    "header": {
      "main": "Для офіційних виплат",
      "accountType": "Тип банківських даних",
      "number": "Номер карти/IBAN",
      "name": "Назва карти/IBAN",
    },
    "tooltip": {
      "edit": "Редагувати"
    }
  },
  "employerForm": {
    "mainInfo": "Головна інформація про роботодавця",
    "type": "Тип юридичної особи",
    "employer": "Роботодавець",
    "edrpou": "Номер ЄДРПОУ",
    "registrationDate": "Дата реєстрації",
    "address": "Адреса роботодавця",
    "phoneNumber": "Номер телефону",
    "endDate": "Дата розірвання договору",
    "paymentInfo": "Інформація про дні виплати зарплати та кількість працівників",
    "paymentDay": "Число місяця зарплати",
    "prePaymentDay": "Число місяця авансу",
    "activeEmployees": "Активні працівники",
    "blockedEmployees": "Заблоковані працівники",
    "allEmployees": "Усі працівники",
    "paymentsAndBalanceInfo": "Виплати та баланс роботодавця",
    "yetToPay": "Залишок до виплати, грн",
    "actualBalance": "Фактичний баланс, грн",
    "plannedBalance": "Запланований баланс, грн",
    "totalPaidThisMonth": "Всього виплачено за місяць, грн",
    "totalPaid": "Всього виплачено за місяць, грн",
    "bankInfo": "Інформація про банківські дані роботодавця",
    "bankName": "Назва банку",
    "mfo": "МФО",
    "bankAccount": "Банківський рахунок",
    "modal": {
      "success": {
        "title": "Успіх!",
        "bankAccountAdding": "Банківський рахунок успішно додано",
        "bankAccountDeleting": "Банківський рахунок успішно видалено"
      }
    }
  },
  "employeeForm": {
    "employee": "Працівник",
    "employer": "Роботодавець",
    "personalData": "Особисті дані",
    "balance": "Баланс",
    "payments": "Історія виплат",
    "history": "Історія змін"
  }, 
  "history": {
    "date": "Дата та час",
    "description": "Операція",
    "author": "Автор",
    "login": "Логін"
  },
  "contacts": {
    "title": "Контакти",
    "description": "Якщо у вас виникли питання, зв'яжіться з нами",
    "address": "м. Київ, вул. Шевченка, 145, офіс 2"
  },
  "userAdditionalData": {
    "switcher": {
      "notification": "Сповіщення",
      "faq": "Часті запитання"
    }
  },
  "notifications": {
    "readAll": "Відмітити всі, як прочитані",
    "title": "Сповіщення",
    "checkAll": "Переглянути усі",
    "empty": "Тут будуть ваші сповіщення"
  },
  "error": {
    "title": "Помилка",
    "network": {
      "header": "Помилка під час обробки даних",
      "default": "Упс, щось сталося, будь ласка, спробуйте пізніше."
    },
    "inner": {
      "1": {
        "label": "Невірно заповнено телефон",
        "description": "Поле повинно бути заповнено та містити символ \"+\" і 12 цифр."
      },
      "2": {
        "label": "Невірно заповнено ІПН",
        "description": "Поле повинно бути заповнено та містити 10 цифр."
      },
      "3": {
        "label": "Невірно заповнено паспорт",
        "description": "Поле повинно бути заповнено."
      },
      "4": {
        "label": "Невірно заповнено код",
        "description": "Поле повинно бути заповнено та містити 6 цифр."
      },
      "5": {
        "label": "Невірно заповнено пароль",
        "description": "Поля повинні бути заповнені однаково та містити щонайменш 8 символів, 1 цифру, 1 малу та 1 велику латиньску літеру."
      },
      "6": {
        "label": "Невірно заповнено поле",
        "description": "Поле повинно бути заповнене."
      },
      "7": {
        "label": "Невірно заповнено пін-код",
        "description": "Поле повинно бути заповнено та містити 4 цифри."
      },
      "8": {
        "label": "Невірно заповнено Email",
        "description": "Поле повинно бути заповнено та містити коректний формат."
      },
      "9": {
        "label": "Невірно обрана мова",
        "description": "Обрана мова не знайдена в листі доступних."
      },
      "10": {
        "label": "Невірно заповнене поле",
        "description": "Значення не відрізняється від попереднього."
      }
    },
    "forms": {
      "required": {
        "label": "Це обов’язкове поле"
      },
      "phone": {
        "label": "Невірно введений номер"
      },
      "email": {
        "label": "Невірно введений email"
      },
      "edrpou": {
        "label": "ЄДРПОУ повинен складатися з 8 чи 10 цифр"
      },
      "itn": {
        "label": "Введіть або ІПН, або номер паспорта",
        "value": "ІПН повинен складатися з 10 цифр"
      },
      "password": {
        "format": "Пароль повинен містити щонайменш 8 символів, 1 цифру, 1 малу та 1 велику латинську літеру",
        "equals": "Поле повинно дорівнювати полю \"Пароль\""
      },
      "number": {
        "positive": "Значення повинне дорівнювати числу більшому за 0"
      },
      "oneOf": {
        "tariffStep": "Поле \"Сума з\" чи \"Сума по\" повинно бути заповнено"
      },
      "date": {
        "label": "Дата повинна бути в форматі дд.мм.рррр"
      },
      "card": {
        "label": "Номер карти повинен складатися з 16 цифр"
      },
      "outOfMonth": {
        "label": "Дата виплати повинна бути існуючою датою місяцю"
      },
      "notSelected": {
        "label": "Оберіть дані з випадаючого списка"
      }
    }
  },
  "footer": {
    "version": "Версія"
  },
  "session": {
    "end": {
      "description": "Ваша сессія скінчилась. Будь ласка, авторизуйтеся ще раз."
    }
  },
  "admin": {
    "employer": {
      "label": "Роботодавець",
      "navigation": {
        "detail": "Загальні дані",
        "employees": "Працівники",
        "tariffs": "Тарифи",
        "history": "Історія змін"
      },
      "history": {
        "creationDate": "Дата та час",
        "commentary": "Коментар",
        "author": "Користувач",
        "description": "Зміна"
      },
      "add": {
        "title": "Додавання роботодавця"
      },
      "balance": {
        "title": "Зміна балансу",
        "label": "Сума зміни",
        "tooltip": {
          "add": "Поповнення",
          "substract": "Відрахування"
        },
        "success": {
          "title": "Успіх!",
          "description": "Баланс успішно змінено"
        },
        "confirmation": {
          "title": "Підтвердження зміни",
          "descriptionStart": "Ви дійсно бажаєте змінити баланс на ",
          "descriptionEnd": " грн?"
        },
        "comment": {
          "label": "Коментар",
          "placeholder": "Причина зміни балансу"
        }
      }
    },
    "user": {
      "label": "Користувач",
      "navigation": {
        "detail": "Особисті дані",
        "history": "Історія змін"
      },
      "add": {
        "title": "Додавання користувача"
      },
      "detail": {
        "title": "Головна інформація про користувача",
        "modal": {
          "success": {
            "title": "Успіх!",
            "change": "Дані користувача успішно змінено",
            "create": "Користувача успішно створено"
          },
          "error": {
            "title": "Помилка!",
            "sameData": "Дані для зміни повинні відрізнятися від попередніх"
          }
        },
        "form": {
          "lastName": {
            "label": "Прізвище",
            "placeholder": "Хвильовий"
          },
          "firstName": {
            "label": "Ім’я",
            "placeholder": "Микола"
          },
          "middleName": {
            "label": "По батькові",
            "placeholder": "Іванович"
          },
          "password": {
            "label": "Пароль"
          },
          "repeatedPassword": {
            "label": "Підтвердіть пароль",
          },
          "role": {
            "label": "Роль користувача",
          },
          "employer": {
            "label": "Роботодавець"
          },
          "address": {
            "placeholder": "м. Київ, вул. Жилянська, 62/64, кв. 12"
          }
        }
      }
    },
    "audit": {
      "detail": {
        "title": "Деталізований аудит зміни",
        "previousTableTitle": "Попередні зміни",
        "field": {
          "phone": "Номер телефону",
          "creationDate": "Дата зміни",
          "description": "Опис",
          "employerFullName": "Назва роботодавця",
          "role": "Роль користувача",
          "fullName": "ПІБ користувача",
          "author": "Автор",
          "comment": "Коментар",
          "value": "Нове значення"
        },
      }
    },
    "employee": {
      "balance": {
        "title": "Інформація про дані балансу на",
        "monthSalary": "Оклад за місяць, грн",
        "dayRate": "Оклад за день, грн",
        "monthWriteOff": "Виведено за місяць, грн",
        "writeOffPossibility": "Доступно для виведення, грн",
        "totalWriteOff": "Всього виведено, грн",
        "totalEarned": "Зароблено, грн"
      },
      "add": {
        "title": "Додавання працівника"
      }
    },
    "tariff": {
      "add": {
        "title": "Додавання тарифу"
      },
      "detail": {
        "title": "Інформація про тариф",
        "modal": {
          "success": {
            "title": "Успіх!",
            "change": "Дані тарифу успішно змінено",
            "create": "Тариф успішно створено"
          },
          "error": {
            "title": "Помилка!"
          },
          "warning": {
            "title": "Увага!",
            "message": "Ви не внесли жодних змін"
          }
        },
        "form": {
          "type": {
            "label": "Тип тарифу"
          },
          "activationDate": {
            "label": "Дата дії, з"
          },
          "deactivationDate": {
            "label": "Дата дії, по"
          },
          "amount": {
            "label": "Сума, грн",
            "placeholder": "0.0 грн"
          },
          "percent": {
            "label": "Відсоток",
            "placeholder": "0.0 %"
          },
          "step": {
            "modal": {
              "title": "Крок виведення"
            },
            "fromAmount": "Сума виведення з, грн",
            "toAmount": "Сума виведення по, грн",
            "rate": "Сума, грн"
          },
          "comment": {
            "label": "Коментар",
            "placeholder": "Пояснення до тарифу"
          },
          "edit": "Редагувати",
          "delete": "Видалити"
        }
      }
    },
    "payments": {
      "detail": {
        "title": "Інформація про виплату",
        "employeesQuantity": {
          "label": "Кількість працівників"
        },
        "totalAmount": {
          "label": "Загальна сума, грн"
        },
        "employerName": {
          "label": "Роботодавець"
        },
        "employeeTable": {
          "headers": {
            "employees": "Працівники",
            "amount": "Сума виплати, грн",
            "itn": "ІПН",
            "passport": "Паспорт"
          },
          "modal": {
            "title": "Виплата працівнику",
            "employee": {
              "label": "Працівник",
              "placeholder": "ПІБ працівника..."
            },
            "amount": {
              "label": "Сума виплати, грн"
            },
            "itn": {
              "label": "ІПН"
            },
            "passport": {
              "label": "Паспорт"
            }
          }
        },
        "creation": {
          "confirmation": {
            "title": "Підтвердження виплати",
            "description": "Ви дійсно бажаєте створити виплату?"
          },
          "success": {
            "title": "Успіх!",
            "description": "Виплату успішно створено"
          },
          "error": {
            "title": "Помилка!",
            "description": "Жодної виплати не створено"
          }
        }
      }
    },
    "employeeDetails": {
      "modal": {
        "error": "Помилка!"
      }
    }
  },
  "employer": {
    "info": {
      "balance": "Баланс компанії",
      "todayPayment": "Сума, виплачена за день",
      "employeesQuantity": "Кількість активних працівників"
    },
    "limits": {
      "navigation": {
        "general": "Глобальний",
        "personal": "Персоналізований"
      },
      "modal": {
        "confirmation": {
          "title": "Деактивація ліміту",
          "question": "Ви дійсно бажаєте деактивувати ліміт?"
        },
        "success": {
          "title": "Успіх!",
          "deactivation": "Ліміт успішно деактивовано",
          "global": "Дані глобального ліміту успішно змінено",
          "personal": "Дані персонального ліміту успішно змінено"
        },
        "fail": {
          "title": "Помилка!",
          "sameData": "Дані для зміни повинні відрізнятися від попередніх."
        }
      },
      "general": {
        "title": "Глобальні ліміти",
        "hold": {
          "label": "Глобальний холд",
          "description": "Заборона на виведення коштів для всіх працівників"
        },
        "percent": {
          "label": "% від суми зарплати",
          "placeholder": "0%"
        },
        "comment": {
          "label": "Опис",
          "placeholder": "Опис глобального холду"
        }
      },
      "private": {
        "title": "Персональний ліміт",
        "frequency": {
          "label": "Частотний ліміт"
        },
        "percent": {
          "label": "% від суми зарплати"
        }
      }
    },
    "employees": {
      "navigation": {
        "personalData": "Особисті дані",
        "limits": "Ліміти"
      }
    }
  },
  "dashboard": {
    "mobile": {
      "toolbar": {
        "profile": "Профіль",
        "main": "Головна",
        "history": "Історія",
        "cards": "Карти"
      },
      "main": {
        "title": "Виведення коштів"
      },
      "profile": {
        "title": "Профіль"
      },
      "history": {
        "title": "Історія",
        "detail": "Додатково"
      }
    },
    "employeePayment": {
      "header": {
        "creationDate": "Дата та час",
        "operationType": "Тип операції",
        "operationView": "Операція",
        "accountType": "Тип банкіських даних",
        "accountNumber": "Номер карти/IBAN",
        "previousBalance": "Сума до, грн",
        "transactionAmount": "Сума операції, грн",
        "subsequentBalance": "Сума після операції, грн",
        "status": "Статус",
        "operationId": "ID операції",
        "receipt": "Квитанція"
      },
      "empty": "Тут буде історія ваших виплат",
      "download": "Завантажити",
      "additionalInfo": "Додатково",
      "title": "Останні операції",
      "all": "Всі"
    },
    "info": {
      "currentAmount": "Зароблена сума на поточний день",
      "availableAmount": "Доступно до виводу на сьогодні",
      "daySalary": "Ставка за день",
      "workingDays": "Кількість робочих днів"
    },
    "welcome": {
      "greeting": "Вітаємо",
      "position": "Посада",
      "organisation": "Компанія"
    },
    "withdrawal": {
      "label": "Сума виводу, грн",
      "comment": "Коментар",
      "commission": "Коміссія за вивод складає",
      "all": "Усі",
      "paymentLabel": "Виплата коштів",
      "cardLabel": "Номер карти / рахунку",
      "success": {
        "title": "Запит відправлено в обробку",
        "description": "Очікуйте проведення операції"
      },
      "confirmation": {
        "title": "Підтвердження виведення коштів",
        "description": "Введіть свій платіжний PIN-код",
        "label": "PIN-код",
        "isForgottenPin": "Забули PIN-код?"
      }
    },
    "card": {
      "label": "Карти та рахунки",
      "add": "Додати",
      "main": "Основна",
      "active": "Активна",
      "blocked": "Заблокована",
      "modal": {
        "title": "Інформація про банківські дані працівника",
        "accountType": "Тип банківських даних",
        "number": "Номер",
        "name": "Назва",
        "status": "Статус карти / рахунку",
        "active": "Активний",
        "main": "Основний",
        "numberPlaceholder": "Номер карти / рахунку",
        "namePlaceholder": "Назва карти / рахунку",
        "error": {
          "title": "Помилка!"
        },
        "confirmation": {
          "changeMain": {
            "title": "Ви дійсно бажаєте змінити основну карту для офіційних виплат?",
            "description": "Лише одна карта/рахунок може бути визначена для офіційної виплати",
            "success": {
              "title": "Успішно змінено!",
              "description": "Успішна зміна карти/рахунку для офіційної виплати"
            }
          },
          "block": {
            "title": "Ви дійсно бажаєте видалити карту ",
            "description": "Після видалення Ви не зможете відновти цю картку/рахунок",
            "success": {
              "title": "Вітаємо!",
              "description": "Ваша карта успішно видалена"
            }
          },
          "edit": {
            "success": {
              "title": "Успішно змінено!",
              "description": "Успішна зміна карти/рахунку"
            }
          },
          "add": {
            "success": {
              "title": "Успішно додано!",
              "description": "Ви успішно додали карту/рахунок"
            }
          },
        }
      },
    }
  },
  "currency": {
    "uah": "грн."
  },
  "forms": {
    "save": "Зберегти",
    "activate": "Активувати",
    "lastName": "Прізвище",
    "name": "Ім'я",
    "middleName": "По батькові",
    "activationDate": "Дата активації",
    "status": "Статус",
    "personalId": "ІПН",
    "phone": "Номер телефону",
    "email": "E-mail",
    "creationDate": "Дата створення",
    "category": "Категорія",
    "birthDate": "Дата народження",
    "address": "Адреса",
    "employer": "Роботодавець",
    "edrpou": "ЄДРПОУ",
    "position": "Посада",
    "workSchedule": "Графік роботи, дні",
    "workHours": "Години роботи, з - по",
    "salary": "Оклад, грн",
    "isWorking": "Працює",
    "state": "Стан",
    "termsOfUse": "Умови користування",
    "policy": "Політика конфіденційності",
    "contract": "Договір доручення",
    "passport": "Паспорт",
    "comment": "Коментар",
    "commentPlaceholder": "Введіть пояснення по документах, якщо не все коректно",
    "givenDocuments": "Подані документи про співпрацю",
    "mainInfo": "Головна інформація про працівника",
    "workInfo": "Інформація про роботу працівника",
    "bankInfo": "Інформація про банківські дані працівника",
    "chooseTime": "Обрати час",
    "from": "з",
    "to": "по",
    "unknown": "Невідомо",
    "notActivated": "Не виконано перший вхід"
  }
}