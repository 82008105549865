import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { ruTranslation } from "./ru/common";
import { ukTranslation } from "./uk/common";
import { getCurrentLanguage } from "../app/constants/language";


const resources = {
    uk: {
        translation: ukTranslation
    },
    ru: {
        translation: ruTranslation
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: getCurrentLanguage(),

        interpolation: {
            escapeValue: false
        }
});

export default i18n;
