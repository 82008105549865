import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "../store";
import {Role} from "../constants/user";
import {ICustomErrorProps} from "../../components/customError/CustomError";
import {Nullable} from "../customTypes";
import { IInnerErrorExtended } from "../constants/error";
import {INetworkErrorExtended} from "../network/models";
import {INotificationProps} from "../../pages/userAdditionalData/NotificationsPage";


export interface IBasicUser {
    fullName: string;
    organisationName: Nullable<string>;
    position: Nullable<string>;
    phone: string;
    email: Nullable<string>;
    version: string;
}

interface IBasicUserNetwork {
    user: {
        name: string;
        organisation: Nullable<string>;
        position: Nullable<string>;
        phone: string;
        email: Nullable<string>;
    }
    version: string;
}

export interface IUserState extends IBasicUser{
    role: Nullable<Role>;
    language: Nullable<number>;
    error: ICustomErrorProps;
    showEndSessionModal: boolean;
    unreadNotifications: INotificationProps[];
}

const initialState: IUserState = {
    role: null,
    fullName: "",
    organisationName: null,
    position: null,
    phone: '',
    email: null,
    language: null,
    version: '',
    showEndSessionModal: false,
    error: {
        inner: null,
        network: null,
        field: null
    },
    unreadNotifications: []
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserPhone: (state, action: PayloadAction<string>) => {
      state.phone = action.payload
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload
    },
    setRole: (state, action: PayloadAction<Nullable<Role>>) => {
      state.role = action.payload
    },
    setBasicUser: (state, action: PayloadAction<IBasicUserNetwork>) => {
        const { name, organisation, position, phone, email } = action.payload.user;

        state.fullName = name
        state.organisationName = organisation
        state.position = position
        state.phone = String(phone)
        state.email = email
        state.version = action.payload.version
    },
    setCurrentLanguage: (state, action: PayloadAction<number>) => {
        state.language = action.payload
    },
    clearError: (state) => {
        state.error.inner = null
        state.error.network = null
        state.error.field = null
    },
    setInnerError: (state, action: PayloadAction<IInnerErrorExtended>) => {
        state.error.inner = action.payload.inner
        state.error.field = action.payload?.field
    },
    setNetworkError: (state, action: PayloadAction<Nullable<INetworkErrorExtended>>) => {
        state.error.network = action.payload?.network
        state.error.field = action.payload?.field
    },
    setUnreadNotifications: (state, action: PayloadAction<INotificationProps[]>) => {
        state.unreadNotifications = action.payload
    },
      setShowEndSessionModal: (state, action: PayloadAction<boolean>) => {
        state.showEndSessionModal = action.payload
    },
    setDefault: (state) => {
        Object.assign(state, initialState)
    }
  }
});


export const { setUserPhone, setEmail, setBasicUser, setCurrentLanguage, setRole, clearError, setInnerError,
    setNetworkError, setDefault, setUnreadNotifications, setShowEndSessionModal } = userSlice.actions;


export const selectHeaderUserData = (state: RootState) => {
    return {
        fullName: state.user.fullName,
        organisationName: state.user.organisationName,
        position: state.user.position
    }
};

export const selectSettingUserData = (state: RootState) => {
    return {
        phone: state.user.phone,
        email: state.user.email,
        language: state.user.language
    }
};

export const selectRole = (state: RootState) => state.user.role;
export const selectUserError = (state: RootState) => state.user.error;
export const selectUnreadNotifications = (state: RootState) => state.user.unreadNotifications;
export const selectFooterUserData = (state: RootState) => state.user.version;
export const selectShowEndSessionModal = (state: RootState) => state.user.showEndSessionModal;

export default userSlice.reducer;