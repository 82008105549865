import { useEffect, useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

// Use throughout app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useDidUpdateEffect = (fn: any, inputs: any[]) => {
    const didMountRef = useRef(false);
  
    useEffect(() => {
      if (didMountRef.current) {
        return fn();
      }
      else {
        didMountRef.current = true;
      }
    }, [fn, ...inputs]); // eslint-disable-line react-hooks/exhaustive-deps
}