import {ApiResponse, ErrorCode, IResponse, NetworkStatus, QueryType, RequestStatus} from "./models";
import i18n from "../../translations/i18n"
import {getCurrentLanguage} from "../constants/language";
import {NetworkRoute} from "./route";
import {store} from "../store";
import {setShowEndSessionModal} from "../reducer/user";
import { CLOSE_SESSION_ERRORS } from "./models";

const createRequest = (type: QueryType, data?: object): RequestInit => {
    return {
        method: type,
        credentials: "include",
        body: data ? JSON.stringify(data): null,
        ...getHeaders()
    };
}

const refreshToken = async () => {
    const response: Response = await fetch(NetworkRoute.user.refresh, createRequest(QueryType.get) );

    const refreshResponse: ApiResponse = await getResponse(response)

    if (!refreshResponse.status) {
        await store.dispatch(setShowEndSessionModal(true))
        throw new Error()
    }
    return refreshResponse
}

const getResponse = async (response: Response): Promise<ApiResponse> => {
    try {
        if (response.status === NetworkStatus.empty){
            return new ApiResponse(RequestStatus.success)
        }

        const json = await response.json();

        if (response.status === NetworkStatus.error){
            return new ApiResponse(RequestStatus.failed, undefined, json)
        }

        if (response.status === NetworkStatus.success){
            return new ApiResponse(RequestStatus.success, json)
        }
        throw new Error()
    } catch (error) {
        return new ApiResponse(
            RequestStatus.failed,
            undefined,
            {code: 500, message: i18n.t("error.network.default")}
        )
    }
}

const getHeaders = () => {
    return {
        headers: {
            "Accept": "application/problem+json",
            "Accept-Language": getCurrentLanguage(),
            "Content-Type": "application/json"
        }
    }
};


const convertCameToSnackCase = (str: string) => str.replace(/[A-Z]/g,
    (letter: string) => `_${letter.toLowerCase()}`);

const convertQueryObject = (queryParams: object): any => {
    return Object.fromEntries(
      Object.entries(queryParams).map(([key, value]) => [convertCameToSnackCase(key), value])
    );
}

export const makeRequest = async(address: string, type: QueryType, data?: object, queryParams?: object ): Promise<IResponse> => {
    try {
        const queryParamsString: string = queryParams ?
            new URLSearchParams(convertQueryObject(queryParams)).toString() :
            "";

        const response: Response = await fetch(queryParamsString ?
            `${address}?${queryParamsString}` : address,
            createRequest(type, data)
        );

        const convertedResponse: ApiResponse = await getResponse(response)

        if (!convertedResponse.status && convertedResponse.error && convertedResponse.error.code === ErrorCode.expired_token){
            await refreshToken()
            return makeRequest(address, type, data, queryParams)
        } else if(!convertedResponse.status && convertedResponse.error
            && Object.values(CLOSE_SESSION_ERRORS).includes(convertedResponse.error.code)) {
            await store.dispatch(setShowEndSessionModal(true))
        }

        return convertedResponse

    } catch (error) {
        return new ApiResponse(
            RequestStatus.failed,
            undefined,
            {code: 500, message: i18n.t("error.network.default")}
        )
    }
};
