export enum AuthorizationStep {
    start = 0,
    phoneConfirmation = 1,
    passwordCreation = 2,
    rulesConfirmation = 3,
    documentConfirmation = 4,
    active = 5,
    pinCreation = 6,
    deviceConfirmation = 7
}

export enum RulesConfirmationStep {
    data = 1,
    rules = 2,
    success = 3,
    end = 4
}

export enum RecoveryStep {
    start,
    confirmation,
    creation,
    success
}