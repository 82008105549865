export enum Role {
    employee = 1,
    employerSuperAdmin = 2,
    admin = 3,
    superAdmin = 4,
    accountant = 5,
    employerAdmin = 6,
    employerAccountant = 7
}

export const EmployerRoles = [Role.employerAccountant, Role.employerAdmin, Role.employerSuperAdmin];
export const AdminRoles = [Role.admin, Role.superAdmin, Role.accountant];
export const AllRoles = [Role.employerAccountant, Role.employerAdmin, Role.employerSuperAdmin, Role.admin,
    Role.superAdmin, Role.accountant, Role.employee]

export enum Status {
    phoneConfirmation = 1,
    passwordCreation = 2,
    rulesConfirmation = 3,
    documentConfirmation = 4,
    activated = 5,
    blocked = 6,
    deleted = 7
}

export enum EmployerStatus {
    activated = 1,
    blocked = 2,
    deleted = 3
}

export enum StatusLabel {
    activated = "activated",
    blocked = "blocked",
    deleted = "deleted"
}


export interface IUserDetailRouteProps {
    location: {
        state: IUserDetailProps
    }
}

export interface IUserDetailProps {
    userId: number;
    userFullName: string;
}
