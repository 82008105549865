import {createSlice, PayloadAction} from "@reduxjs/toolkit";

import {RootState} from "../store";
import {Nullable} from "../customTypes";
import {IAccount, IDashboard, IAccountModal, WithdrawStep} from "../constants/dashboard";
import { IComboboxDataProps } from "../../components/combobox/Combobox";


export interface IWithdrawDetail {
    amount: Nullable<number | string>;
    commission: Nullable<number | string>;
    step: Nullable<WithdrawStep>;
    id: Nullable<number>
}

export interface IDashboardState {
    withdrawDetail: IWithdrawDetail;
    info: Nullable<IDashboard>;
    accountModal: IAccountModal;
}

export const initialWithdrawDetail: IWithdrawDetail = {
    amount: null,
    commission: null,
    step: null,
    id: null
};

export const initialAccountModal: IAccountModal = {
    isOpen: false,
    data: null,
    accountTypes: null
}

const initialState: IDashboardState = {
    withdrawDetail: initialWithdrawDetail,
    info: null,
    accountModal: initialAccountModal
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
      setWithdrawDetail: (state, action: PayloadAction<IWithdrawDetail>) => {
          state.withdrawDetail = action.payload
      },
      setWithdrawStep: (state, action: PayloadAction<WithdrawStep>) => {
          state.withdrawDetail.step = action.payload
      },
      setDashboardInfo: (state, action: PayloadAction<Nullable<IDashboard>>) => {
          state.info = action.payload
      },
      setAccounts: (state, action: PayloadAction<IAccount[]>) => {
          if (state.info) {
              state.info.accounts = action.payload
          }
      },
      switchAccountModal: (state, action: PayloadAction<boolean>) => {
          state.accountModal.isOpen = action.payload
      },
      setAccountModalData: (state, action: PayloadAction<Nullable<IAccount>>) => {
          state.accountModal.data = action.payload
      },
      setAccountTypes: (state, action: PayloadAction<IComboboxDataProps[]>) => {
          state.accountModal.accountTypes = action.payload;
      },
      setDefault: (state) => {
        Object.assign(state, initialState)
      }
  }
});

export const { setDefault, setWithdrawDetail, setWithdrawStep, setDashboardInfo, setAccounts, switchAccountModal,
    setAccountModalData, setAccountTypes } = dashboardSlice.actions;

export const selectWithdrawDetail = (state: RootState) => state.dashboard.withdrawDetail;
export const selectDashboardInfo = (state: RootState) => state.dashboard.info;
export const selectAccounts = (state: RootState) => state.dashboard.info?.accounts;
export const selectBalance = (state: RootState) => state.dashboard.info?.balance;
export const selectUserBasic = (state: RootState) => state.dashboard.info?.user;
export const selectWorkingDays = (state: RootState) => state.dashboard.info?.workingDays;
export const selectAccountModal = (state: RootState) => state.dashboard.accountModal;
export const selectAccountTypes = (state: RootState) => state.dashboard.info?.selectableData.accountTypes ?? state.dashboard.accountModal.accountTypes;
export const selectSelectableData = (state: RootState) => state.dashboard.info?.selectableData;

export default dashboardSlice.reducer;