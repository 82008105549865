import React, { memo } from "react";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalOverlay
} from "@chakra-ui/react";

import {PrimaryButton, SecondaryButton} from "../../components/button/Button";
import {SuccessIcon} from "../../components/icons/Icons";


export interface IBasicModalProps {
    isOpen: boolean;
    onClose: () => void;
    onConfirm: () => void;
    title?: string;
    description?: string;
    secondaryText?: string;
    primaryText?: string;
    mainInfo?: string;
    successIcon?: boolean;
}

const BasicModal = ( { isOpen, onClose, onConfirm, description, secondaryText, primaryText, mainInfo, title, successIcon }: IBasicModalProps )
    : JSX.Element => {

    return (
        <Modal isOpen = { isOpen } onClose = { onClose } isCentered closeOnOverlayClick={false} blockScrollOnMount={true} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton color="#267FFF"/>
            <ModalBody flex="true" textAlign="center"
                       paddingTop="3rem" alignItems="center" justifyContent="center"
                       textColor="rgba(64, 92, 133, 0.6)" className="flex justify-center align-center text-center">
                <div className="px-10">
                    {
                        successIcon ?
                            <div className="my-12">
                              <SuccessIcon height={'5.5rem'} width={'5.5rem'}/>
                            </div> :
                            null
                    }
                    {
                        title ?
                            <h3 className="text-darkblue font-bold mb-5">{ title }</h3> :
                            null
                    }
                    <div className="text-darkblue-light">
                        { description } <span className="text-darkblue font-bold">{mainInfo ? mainInfo : ""}</span>
                    </div>
                </div>
            </ModalBody>

            <ModalFooter flex="true" marginBottom="1rem" justifyContent="center">
                {
                    secondaryText ?
                        <SecondaryButton w="10rem" mr={3} onClick = { onClose }>
                            { secondaryText }
                        </SecondaryButton> :
                        null
                }
                  <PrimaryButton w="10rem" onClick = { onConfirm }>
                      { primaryText }
                  </PrimaryButton>

            </ModalFooter>
          </ModalContent>
        </Modal>
    )
}

export default memo(BasicModal);