export enum SubRoute {
    detail = "detail",
    tariffs = "tariffs",
    employees = "employees",
    history = "history",
    payments = "payments",
    balance = "balance",
    limits = "limits"
}

export const InnerRoute = {
    default: "/",
    login: "/login",
    loginMobileNavigation: "/login/mobile/navigation",
    settingsMobile: "/settings/mobile",
    registration: "/registration",
    termsOfUse: "/terms-of-use",
    privacyPolicy: "/privacy-policy",
    contractOfAgency: "/contract-of-agency",
    passwordRecovery: "/password-recovery",
    settings: "/settings",
    settingsMenu: "/settings/menu",
    changeData: "/change-data",
    dashboard: "/dashboard",
    employeeDetails: "/employee-details",
    contacts: "/contacts",
    notifications: "/notifications",
    faq: "/faq",
    history: "/history",
    // admin
    adminEmployees: "/admin/employees",
    adminEmployee: "/admin/employee",
    adminEmployeeDetail: `/admin/employee/${SubRoute.detail}`,
    adminEmployeeBalance: `/admin/employee/${SubRoute.balance}`,
    adminEmployeePayments: `/admin/employee/${SubRoute.payments}`,
    adminEmployeeHistory: `/admin/employee/${SubRoute.history}`,
    adminEmployers: "/admin/employers",
    adminEmployer: "/admin/employer",
    adminEmployerTariffs: `/admin/employer/${SubRoute.tariffs}`,
    adminEmployerDetail: `/admin/employer/${SubRoute.detail}`,
    adminEmployerEmployees: `/admin/employer/${SubRoute.employees}`,
    adminEmployerHistory: `/admin/employer/${SubRoute.history}`,
    adminUsers: "/admin/users",
    adminUser: "/admin/user",
    adminUserDetail: `/admin/user/${SubRoute.detail}`,
    adminUserHistory: `/admin/user/${SubRoute.history}`,
    adminPayments: "/admin/payments",
    adminAnalytics: "/admin/analytics",
    adminAudit: "/admin/audit",
    adminAuditDetail: "/admin/audit/detail",
    adminTariffs: "/admin/tariffs",
    adminTariffsDetail: `/admin/tariff/${SubRoute.detail}`,
    paymentDetail: `/payment/${SubRoute.detail}`,
    // employer
    employerEmployees: "/employer/employees",
    employerEmployeeDetail: `/employer/employees/${SubRoute.detail}`,
    employerEmployeeLimit: `/employer/employees/${SubRoute.limits}`,
    employerPayments: "/employer/payments",
    employerLimits: `/employer/limits`,
    employerLimitDetail: `/employer/limits/${SubRoute.detail}`,
    employerLimitsEmployees: `/employer/limits/${SubRoute.employees}`,
    //employee
    withdrawConfirmation: "/dashboard/withdraw/confirmation",
    dashboardNew: '/dashboard-new',
    dashboardMobileProfile: "/dashboard/mobile/profile",
    dashboardMobileHistory: "/dashboard/mobile/history",
    dashboardMobileCards: "/dashboard/mobile/cards",
    dashboardExtendedPayments: "/dashboard/payments/detail"
}

export const dashboardSubPages: string[] = [
      InnerRoute.notifications, InnerRoute.faq, InnerRoute.settings, InnerRoute.history, InnerRoute.dashboardNew,
    InnerRoute.dashboardExtendedPayments
];

export interface ILoggedLocationProps {
    location: {
        state: {
            isLoggedIn?: boolean
        }
    }
}